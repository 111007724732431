import React, { useState } from "react";
import axios from "axios";
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Table,
  Alert,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import NormalLayout from "../../components/layout/NormalLayout";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

const Pickup = () => {
  const [keyword, setKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProductCodes, setSelectedProductCodes] = useState([]);
  const [memo, setMemo] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const [visibleAlert, setVisibleAlert] = useState(false); // Alert 노출 여부
  const [alertMsg, setAlertMsg] = useState("");
  const onDismiss = () => setVisibleAlert(false);

  const performSearch = async (e) => {
    e.preventDefault();

    if (keyword === "") {
      setSearchResults([]);
      alert("검색어를 입력해 주세요. ");
      return;
    }

    // 주문가능 여부(Deposit) 확인
    // 신용구매 가능 여부
    if (
      !cookies.get("status").c &&
      parseFloat(cookies.get("balance").deposit) <= 0
    ) {
      setAlertMsg("신용구매 전환 또는 선불충전이 필요합니다.");
      setVisibleAlert(true);
      return;
    }

    // 검색
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/search/product/pickup?keyword=${keyword}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.status === 200) {
        setSearchResults(resList.data);
        console.log(resList.data);
        if (resList.data.length === 0) {
          alert(`입력하신 '${keyword}'에 대한 제품은 없습니다.`);
        }
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const handleProductSelect = (product) => {
    setSelectedProducts([...selectedProducts, { ...product, qauntity: 1 }]);
    setSelectedProductCodes([...selectedProductCodes, product.product_code]);
    console.log([...selectedProductCodes, product.product_code]);
  };

  const handleqauntityChange = (code, qauntity) => {
    if (qauntity <= 0) {
      alert("최소수량은 1개 이상입니다.");
      qauntity = 1;
    }
    setSelectedProducts(
      selectedProducts.map((p) =>
        p.product_code === code ? { ...p, qauntity } : p
      )
    );
  };

  const handleDeleteProduct = (code) => {
    setSelectedProducts(
      selectedProducts.filter((p) => p.product_code !== code)
    );
    setSelectedProductCodes(
      selectedProductCodes.filter((item) => item !== code)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 이미 제출 중이면 추가 클릭 무시
    if (isSubmitting) return;

    setIsSubmitting(true);

    console.log("주문 완료:", { selectedProducts, memo });
    // 픽업 주문 등록 후 목록으로 이동하기

    let orders = [];
    selectedProducts.map((p) => {
      orders.push({
        qauntity: p.qauntity,
        unitPrice: p.price,
        productCode: p.product_code,
        productName: p.en_name,
      });
    });

    try {
      await axios
        .post(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/pickup`,
          {
            orders,
            memo,
          },
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        )
        .then((data) => {
          if (data.data.result === "success") {
            navigate("/pickuplist");
          }
        })
        .catch((Error) => {
          console.log(Error);
        });
    } catch (error) {
      console.error("제출 중 오류 발생:", error);
      // 오류 처리 로직 (예: 사용자에게 오류 메시지 표시)
    } finally {
      setIsSubmitting(false);
    }
  };

  const calculateTotal = () => {
    return selectedProducts.reduce(
      (total, product) => total + product.price * product.qauntity,
      0
    );
  };

  const handleReset = () => {
    setKeyword("");
    setSearchResults([]);
  };

  // const handleKeyPressSearch = (e) => {
  //   if (e.charCode === 13) {
  //     performSearch();
  //   }
  // };

  return (
    <NormalLayout>
      <div>
        <h3 className="mb-4">픽업주문</h3>

        <Row className="mb-3 mt-2">
          <Col>
            <h4>제품 검색</h4>
          </Col>
        </Row>
        <div className="my-4 p-3 border rounded bg-light">
          <Form onSubmit={performSearch}>
            <FormGroup>
              <Label for="keyword">제품코드 또는 제품명으로 검색하세요.</Label>
              <InputGroup className="text-center">
                <Input
                  type="text"
                  id="keyword"
                  className="input500"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder="검색어를 입력해주세요."
                />
                <Button type="submit" color="primary">
                  검색
                </Button>{" "}
                <Button
                  style={{ marginLeft: "10px" }}
                  color="warning"
                  onClick={() => handleReset()}
                >
                  검색 초기화
                </Button>
              </InputGroup>
            </FormGroup>
          </Form>

          {searchResults.length > 0 && (
            <>
              <Row>
                <Col>
                  <h4 className="">"{keyword}" 검색 결과</h4>
                </Col>
              </Row>
              <Row className="mb-4 p-2">
                {searchResults.length > 0 && (
                  <Table striped bordered hover className="mt-3 p-2">
                    <thead>
                      <tr className="table-primary">
                        <th className="text-center">담기</th>
                        <th className="text-center">제품코드</th>
                        <th className="text-center">제품명</th>
                        <th className="text-center">공급가</th>
                        <th className="text-center">재고상태</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchResults.map((product, idx) => (
                        <tr key={product.product_code}>
                          <td className="text-center">
                            {selectedProductCodes.includes(
                              product.product_code
                            ) ? (
                              <Button
                                color="warning"
                                size="sm"
                                onClick={() =>
                                  handleDeleteProduct(product.product_code)
                                }
                              >
                                완료
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => handleProductSelect(product)}
                              >
                                담기
                              </Button>
                            )}
                          </td>
                          <td className="text-center">
                            {product.product_code}
                          </td>
                          <td style={{ textAlign: "left" }}>
                            {product.en_name}
                            {product.point === "Y" && (
                              <span className="text-danger bold_800">[P]</span>
                            )}
                          </td>
                          <td className="text-center" style={{ width: "70px" }}>
                            {product.price}
                          </td>
                          <td
                            className="text-center"
                            style={{
                              width: "80px",
                              backgroundColor:
                                product.stock_status === "empty"
                                  ? "lightgrey"
                                  : product.stock_status,
                            }}
                          >
                            {product.stock_status === "empty" && "0"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Row>
            </>
          )}
        </div>
        <Alert color="danger" isOpen={visibleAlert} toggle={onDismiss}>
          {alertMsg}
        </Alert>
        <div className="border p-2 mt-3 bg-light">
          <h4 className="mb-3 mt-2">주문 제품</h4>
          <Table>
            <thead>
              <tr>
                <th>제품명</th>
                <th>가격</th>
                <th>수량</th>
                <th>소계</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              {selectedProducts.map((product, idx) => (
                <tr key={idx}>
                  <td>{product.en_name}</td>
                  <td>${product.price}</td>
                  <td>
                    <Input
                      type="number"
                      value={product.qauntity}
                      onChange={(e) =>
                        handleqauntityChange(
                          product.product_code,
                          parseInt(e.target.value)
                        )
                      }
                      min={1}
                      step={1}
                      style={{ width: "70px" }}
                    />
                  </td>
                  <td>${(product.price * product.qauntity).toFixed(2)}</td>
                  <td>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDeleteProduct(product.product_code)}
                    >
                      X
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="3" className="text-right">
                  <strong>총계:</strong>
                </td>
                <td>
                  <strong>${calculateTotal().toFixed(2)}</strong>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </Table>

          <Form onSubmit={handleSubmit} className="mt-4">
            <FormGroup>
              <Label for="memo">메모</Label>
              <Input
                type="text"
                name="memo"
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
              />
            </FormGroup>
            <Button
              color="success"
              type="submit"
              block
              disabled={
                selectedProducts.length === 0 ||
                parseFloat(cookies.get("balance").deposit) < calculateTotal()
              }
            >
              {isSubmitting
                ? "처리 중..."
                : parseFloat(cookies.get("balance").deposit) < calculateTotal()
                ? "신용구매 전환 또는 선불충전이 필요합니다."
                : "픽업주문 신청하기"}
            </Button>
          </Form>
        </div>
      </div>
    </NormalLayout>
  );
};

export default Pickup;
