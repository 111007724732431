import React, { useState, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import axios from "axios";
import {
  Table,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

function Registered() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수
  const [count, setCount] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false); // 드롭다운의 열림/닫힘 상태
  const [pageSize, setPageSize] = useState(10); // 페이지당 노출수 설정

  const toggle = () => setDropdownOpen((prevState) => !prevState); // 드롭다운 상태 토글

  // 드롭다운에서 항목 선택 시, 페이지당 노출수 업데이트
  const selectPageSize = (size) => {
    setCurrentPage(0);
    setPageSize(size);
  };

  useEffect(() => {
    excelListData();
  }, [currentPage, pageSize]);

  const excelListData = async () => {
    try {
      const encodedWholesaler = encodeURIComponent(cookies.get("info").profile);
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/manage/order?status=REGISTERED&page=${currentPage}&limit=${pageSize}&wholesaler=${encodedWholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      // 데이터가 있을 경우에만 표시
      if (resList.data.result === "success") {
        setData(resList.data.data.list);
        setCount(resList.data.data.count.count);
        setPageCount(Math.ceil(resList.data.data.count.count / pageSize));
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  // 주문건별 총 상품금액 계산함수
  const genAmount = (items) => {
    let amount = 0.0;

    items.map((item) => {
      let price = convertPriceFormat(
        item.productCode,
        item.unitPrice,
        item.qauntity
      ).replace("$", "");
      if (!isNaN(price)) {
        amount += parseFloat(price);
      }
    });

    return amount.toFixed(2);
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    let resValue = null;
    let pcode = productCode.toUpperCase();

    // 예외처리 코드 : BRING, CHEMIST, MART, PROMO, SPECIAL, 공백
    if (pcode === "BRING" || pcode === "PROMO") {
      resValue = "$0";
    } else if (
      pcode === "CHEMIST" ||
      pcode === "MART" ||
      pcode === "SPECIAL" ||
      pcode === ""
    ) {
      resValue = "미정";
    } else {
      resValue =
        "$" +
        parseFloat(unitPrice * qauntity)
          .toFixed(2)
          .toString();
    }

    return resValue;
  };

  const deleteOrder = async (orderIndex, wholesaler) => {
    try {
      const encodedWholesaler = encodeURIComponent(wholesaler);
      const resList = await axios.delete(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/manage/order?orderindex=${orderIndex}&wholesaler=${encodedWholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (resList.data.result === "success") {
        excelListData();
      } else {
        console.error("주문건을 삭제하는데 실패했습니다");
      }
    } catch (error) {
      console.error("주문건을 삭제하는데 실패했습니다", error);
    }
  };

  const handleDelete = (orderIndex, wholesaler, name) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(`${name}님 주문을 삭제하시겠습니까?`);

    if (isConfirmed) {
      deleteOrder(orderIndex, wholesaler);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("사용자가 삭제를 취소했습니다.");
    }
  };

  return (
    <NormalLayout>
      <div>
        <h3>주문등록 완료 {count && count !== 0 ? <>({count}건)</> : <></>}</h3>
      </div>
      <div className="p-3"></div>
      {data ? (
        <>
          <div className="border p-3">
            <div className="mb-3">
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret color="dark">
                  페이지당 노출 수: {pageSize}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => selectPageSize(10)}>
                    10개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(30)}>
                    30개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(50)}>
                    50개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(100)}>
                    100개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(300)}>
                    300개
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>{" "}
            </div>
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center">No</th>
                  <th className="text-center p-2 border">요청메모</th>
                  <th className="text-center p-2 border">이름</th>
                  <th className="text-center p-2 border">전화번호</th>
                  <th className="text-center p-2 border">모바일번호</th>
                  <th className="text-center p-2 border">통관부호</th>
                  <th className="text-center p-2 border">우편번호</th>
                  <th className="text-center p-2 border">주소</th>
                  <th className="text-center p-2 border">배송메모</th>
                  <th className="text-center p-2 border">주문상품</th>
                  <th className="text-center p-2 border">
                    예상금액
                    <br />
                    (배송비 제외)
                  </th>
                  <th className="text-center p-2 border">예상출고지</th>
                  <th className="text-center p-2 border">쇼핑몰주문번호</th>
                  <th className="text-center p-2 border">등록일</th>
                  <th className="text-center p-2 border">기능</th>
                </tr>
              </thead>
              <tbody>
                {data.map((o, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">
                        {idx + 1 + currentPage * pageSize}
                      </td>
                      <td className="text-center">{o.shipper_memo}</td>
                      <td className="text-center">{o.customer_name}</td>
                      <td className="text-center">{o.customer_tel}</td>
                      <td className="text-center">{o.customer_mobile}</td>
                      <td className="text-center">{o.pcc}</td>

                      <td className="text-center">{o.zip}</td>
                      <td>{o.address}</td>

                      <td>{o.delivery_memo}</td>
                      <td style={{ padding: "0px" }}>
                        <Table
                          bordered
                          style={{ margin: "0px", width: "100%" }}
                        >
                          <tbody>
                            {o.order_product.map((i, index) => {
                              return (
                                <tr key={index}>
                                  <td className="cell70">{i.productCode}</td>

                                  <td className="cell">{i.productName}</td>
                                  <td
                                    style={{ width: "60px" }}
                                    className="text-center"
                                  >
                                    {i.qauntity}개
                                  </td>
                                  <td
                                    style={{ width: "70px" }}
                                    className="text-center"
                                  >
                                    {convertPriceFormat(
                                      i.productCode,
                                      i.unitPrice,
                                      i.qauntity
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>
                      <td className="text-center">
                        ${genAmount(o.order_product)}
                      </td>
                      <td className="text-center">{o.departure_place}</td>
                      <td className="text-center">{o.wholesaler_order_no}</td>
                      <td className="text-center">
                        <Moment format="YYYY-MM-DD">{o.created_at}</Moment>
                      </td>
                      <td className="text-center" style={{ width: "80px" }}>
                        <Button
                          color="danger"
                          style={{ fontSize: "12px" }}
                          onClick={() =>
                            handleDelete(
                              o.order_index,
                              o.wholesaler,
                              o.customer_name
                            )
                          }
                        >
                          삭제
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReactPaginate
                previousLabel={"이전"}
                nextLabel={"다음"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"item active "}
                breakClassName={"item break-me "}
                pageClassName={"item pagination-page "}
                disabledClassName={"disabled-page"}
              />
            </div>
          </div>
        </>
      ) : (
        <Table hover bordered>
          <thead>
            <tr className="table-primary">
              <th className="text-center">No</th>
              <th className="text-center p-2 border">요청메모</th>
              <th className="text-center p-2 border">이름</th>
              <th className="text-center p-2 border">전화번호</th>
              <th className="text-center p-2 border">모바일번호</th>
              <th className="text-center p-2 border">통관부호</th>
              <th className="text-center p-2 border">우편번호</th>
              <th className="text-center p-2 border">주소</th>
              <th className="text-center p-2 border">배송메모</th>
              <th className="text-center p-2 border">주문상품</th>
              <th className="text-center p-2 border">
                예상금액
                <br />
                (배송비 제외)
              </th>
              <th className="text-center p-2 border">예상출고지</th>
              <th className="text-center p-2 border">쇼핑몰주문번호</th>
              <th className="text-center p-2 border">등록일</th>
              <th className="text-center p-2 border">기능</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={15} className="text-center">
                주문등록 완료 건이 없습니다.
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </NormalLayout>
  );
}

export default Registered;
