import axios from "axios";
import moment from "moment";
import { Cookies } from "react-cookie";
import Moment from "react-moment";
import { orderStatus, paymentMethod } from "../../utils/ConvertWord";

const cookies = new Cookies();

// 주문번호 클릭시 새창으로 띄워서 출력하는 코드
export const orderDetail = async (orderNo) => {
  let orderData = null;
  console.log("order no ", orderNo);

  const resInfo = await axios.get(
    `${process.env.REACT_APP_WHOLESALE_API_URL}/search/order/${orderNo}`,
    {
      headers: {
        Authorization: cookies.get("info").token,
      },
    }
  );

  console.log("결과 ", resInfo);
  orderData = resInfo.data[0];

  let amount = 0;
  orderData.order_product.map((product) => {
    amount += product.qauntity * parseFloat(product.unitPrice);
  });

  function formatDate(dateString) {
    return new Date(dateString).toLocaleString();
  }

  // 검색된 결과에 따른 내용정보 가공
  // 1. 성공일 때 : data.res='success'
  // 2. 실패일 때 : data.res='fail'

  let content = ``;

  const printWindow = window.open("", "_blank", "width=600, height=600");

  printWindow.document.write(`
<!DOCTYPE html>
<html lang="ko">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>주문 상세 정보</title>
    <link href="https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.1.3/css/bootstrap.min.css" rel="stylesheet">
    <style>
        body { padding: 20px; font-family: 'Noto Sans KR', sans-serif;}
        .badge { font-size: 100%; }
    </style>
</head>
<body>
<div class="container">
        <h1 id="orderTitle" class="mb-4">주문 상세 정보</h1>
  <li><strong>주문번호 :</strong> ${orderData.order_no} </li><br/>
        <h5>기본 정보</h5>
      
        <table class="table table-bordered">
            <tbody>
                <tr>
                    <th>업체명</th>
                    <td id="wholesaler" colspan='3'>${orderData.wholesaler}</td>
                </tr>
                <tr>
                    <th>운송장 번호</th>
                    <td id="hawb">${orderData.hawb ? orderData.hawb : ""}</td>
                    <th>주문 상태</th>
                    <td id="orderStatus">${orderStatus(
                      orderData.order_status
                    )}</td>
                </tr>
                <tr>
                    <th>고객명</th>
                    <td id="customerName">${orderData.customer_name}</td>
                    <th>연락처</th>
                    <td id="customerTel">${orderData.customer_tel}</td>
                </tr>
                <tr>
                    <th>주문일</th>
                    <td id="createdAt">${moment(orderData.created_at).format(
                      "YYYY-MM-DD, hh:mm"
                    )}</td>
                    <th>배송일</th>
                    <td id="deliveryAt">${
                      orderData.delivery_at
                        ? moment(orderData.delivery_at).format(
                            "YYYY-MM-DD, hh:mm"
                          )
                        : ""
                    }</td>
                </tr>
            </tbody>
        </table>

        <div class="p-2 mb-3 mt-4 border rounded">
        <h5>주문 제품</h5>
        <table class="table">
            <thead>
                <tr>
                    <th>제품 코드</th>
                    <th>제품명</th>
                    <th>수량</th>
                    <th>단가</th>
                    <th>소계</th>
                </tr>
            </thead>
            <tbody id="orderProducts">
            ${orderData.order_product
              .map(
                (product) => `
              <tr>
                  <td width="100px">${product.productCode}</td>
                  <td>${product.productName}</td>
                  <td width="50px">${product.qauntity}</td>
                  <td width="70px">$${product.unitPrice}</td>
                  <td width="70px">$${(
                    product.qauntity * product.unitPrice
                  ).toFixed(2)}</td>
              </tr>
          `
              )
              .join("")}
              <tr>
                  <td align="center" colspan=4>총액</td>
                  <td width="70px">$${amount.toFixed(2)}</td>
              </tr>
            </tbody>
        </table>
        </div>
        
        <div class="p-2 mb-3 mt-4 border rounded">
        <h5>배송비</h5>
        <p id="deliveryFee">${
          orderData.delivery_fee !== null
            ? `$${orderData.delivery_fee}`
            : "없음"
        }</p>
        </div>

        <div class="p-2 mb-3 mt-4 border rounded">
        <h5>결제</h5>
        <ul id="transactions" class="list-group">
        <li class="list-group-item">총 결제금액(제품+배송비) : ${
          orderData.delivery_fee !== null
            ? `$${(
                parseFloat(amount) + parseFloat(orderData.delivery_fee)
              ).toFixed(2)}`
            : `$${amount.toFixed(2)}`
        }</li>
          </ul><br/>
        <h5>결제 방법</h5>
        <ul id="transactions" class="list-group">
         ${orderData.transactions
           .map(
             (transaction) => `
                <li class="list-group-item">${paymentMethod(
                  transaction.type
                )}: $${Math.abs(transaction.amount).toFixed(2)}</li>
            `
           )
           .join("")}
        </ul>
        </div>

 <div class="p-2 mb-3 mt-4 border rounded">
        <h5>상태 변경 이력</h5>
        <ul id="statusHistory" class="list-group">
            ${
              orderData.status_history &&
              orderData.status_history.status &&
              orderData.status_history.status.length > 0
                ? orderData.status_history.status
                    .map(
                      (status) => `
                        <li class="list-group-item">
                            ${formatDate(status.updated_at)}: ${orderStatus(
                        status.before
                      )} → ${orderStatus(status.after)}
                        </li>
                      `
                    )
                    .join("")
                : `<li class="list-group-item">상태변경 내역 없음</li>`
            }
        </ul>
    </div>


</body>
</html>
`);
};
