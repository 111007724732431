import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  FormGroup,
  Button,
  Input,
} from "reactstrap";

import axios from "axios";
import { Cookies } from "react-cookie";
import InvoicePaid from "./InvoicePaid";
import InvoiceUnpaid from "./InvoiceUnpaid";

const cookies = new Cookies();

function AdminInvoice({ isActive }) {
  const [activeTab, setActiveTab] = useState("인보이스 정산 대상");
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [invoices, setInvoices] = useState([]);
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [paidInvoices, setPaidInvoices] = useState([]);
  const [unpaidInvoices, setUnpaidInvoices] = useState([]);

  // const [detailInfo, setDetailInfo] = useState([]);

  // const currentYear = new Date().getFullYear();
  // const currentMonth = new Date().getMonth() + 1;

  // const years = Array.from({ length: currentYear - 2022 }, (_, i) =>
  //   (2023 + i).toString()
  // );
  // const months = [
  //   "1",
  //   "2",
  //   "3",
  //   "4",
  //   "5",
  //   "6",
  //   "7",
  //   "8",
  //   "9",
  //   "10",
  //   "11",
  //   "12",
  // ];

  useEffect(() => {
    // 해당 년월을 기준으로 인보이스 정보 가져오기

    if (isActive) {
      // getInvoice();
    }
  }, [isActive]);

  // // 인보이스 목록 가져오기
  // const getInvoice = async (checkYear, checkMonth) => {
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/invoice/${checkYear}/${checkMonth}`,
  //       {
  //         headers: {
  //           Authorization: cookies.get("info").token,
  //         },
  //       }
  //     );

  //     if (res.data.result === "success") {
  //       let tempInvoices = res.data.data.list;
  //       setInvoices(tempInvoices);
  //       // pending, unpaid, paid 구분
  //       let tempPending = [];
  //       let tempUnpaid = [];
  //       let tempPaid = [];

  //       tempInvoices.map((invoice) => {
  //         if (invoice.invoice_status === "pending") {
  //           tempPending.push(invoice);
  //         } else if (invoice.invoice_status === "unpaid") {
  //           tempUnpaid.push(invoice);
  //         } else {
  //           tempPaid.push(invoice);
  //         }
  //       });

  //       setPendingInvoices(tempPending);
  //       setPaidInvoices(tempPaid);
  //       setUnpaidInvoices(tempUnpaid);
  //     } else {
  //       setInvoices([]);
  //     }
  //   } catch (error) {
  //     console.error("데이터를 불러오는데 실패했습니다", error);
  //   }
  // };

  // const handleSearch = () => {
  //   setPaidInvoices([]);
  //   setPendingInvoices([]);
  //   setUnpaidInvoices([]);

  //   if (year > currentYear) {
  //     alert("날짜 범위를 벗어났습니다.");
  //   } else {
  //     if (year === currentYear && month > currentMonth) {
  //       alert("날짜 범위를 벗어났습니다.");
  //     } else {
  //       getInvoice(year, month);
  //     }
  //   }
  // };

  // const handleGetInvoiceDetail = async (
  //   selectedYear,
  //   selectedMonth,
  //   wholesaler
  // ) => {
  //   console.log("hello", selectedYear, selectedMonth, wholesaler);
  //   try {
  //     const resList = await axios.get(
  //       `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/invoice/${selectedYear}/${selectedMonth}/${wholesaler}`,
  //       {
  //         headers: {
  //           Authorization: cookies.get("info").token,
  //         },
  //       }
  //     );
  //     const res = resList.data;
  //     console.log("확인", res.data.list);
  //     if (res.result === "success") {
  //       setDetailInfo(res.data.list);
  //     } else {
  //       // alert("인보이스 상세정보 건이 없습니다.");
  //       setDetailInfo([]);
  //     }
  //   } catch (error) {
  //     console.error("데이터를 불러오는데 실패했습니다", error);
  //   }
  // };

  return (
    <>
      {/* <div className="p-3 border my-4 bg-light">
        <Form inline="true">
          <FormGroup className="mr-3 d-flex align-items-center">
            <Input
              type="select"
              id="yearSelect"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              className="mr-3"
              style={{ width: "100px" }}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Input>
            <span style={{ marginRight: "20px" }}>년</span>
            <Input
              type="select"
              id="monthSelect"
              value={month}
              onChange={(e) => setMonth(parseInt(e.target.value))}
              className="mr-3"
              style={{ width: "100px" }}
            >
              {months.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </Input>
            <span style={{ marginRight: "20px" }}>월</span>
            <Button color="primary" onClick={handleSearch}>
              검색
            </Button>
          </FormGroup>
        </Form>
        <div>
          <li>인보이스 정산 확인 대상 : {unpaidInvoices.length}건</li>
          <li>인보이스 정산 완료 : {paidInvoices.length}건</li>
        </div>
      </div> */}

      <Nav tabs className="mb-3">
        {/*           "인보이스 발행 대상", */}
        {["인보이스 정산 대상", "인보이스 정산 완료"].map((tab) => (
          <NavItem key={tab} className="bg-light">
            <NavLink
              className={activeTab === tab ? "active" : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Container style={{ marginLeft: "0px" }}>
        <TabContent activeTab={activeTab}>
          {/* <TabPane tabId="인보이스 발행 대상" className="border-0">
            <InvoicePending
              isActive={activeTab === "인보이스 발행 대상"}
              invoices={pendingInvoices}
            />
          </TabPane> */}
          <TabPane tabId="인보이스 정산 대상" className="border-0">
            <InvoiceUnpaid isActive={activeTab === "인보이스 정산 대상"} />
          </TabPane>
          <TabPane tabId="인보이스 정산 완료" className="border-0">
            <InvoicePaid isActive={activeTab === "인보이스 정산 완료"} />
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
}

export default AdminInvoice;
