import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import axios from "axios";
import { Navigate } from "react-router-dom";
import {
  Container,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Row,
  Col,
  Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import Footer from "../components/layout/Footer";
import logo from "../../src/assets/img/logo.png";
import slogan from "../../src/assets/img/img_slogan.svg";

const cookies = new Cookies();

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const { user, isLoading, signIn } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError("");

    try {
      await signIn(email, password);
      // company, user 상태 확인
      // 사용자 profile eoncoding
      const encodedWholesaler = encodeURIComponent(cookies.get("info").profile);

      // 도매업체 정보 가져오기
      const resCompany = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/dashboard/company?wholesaler=${encodedWholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      // 도매업체 정보가 없거나 활성화가 안 된 경우 다른 페이지로 이동
      // 도매업체 정보가 없는 경우 : 업체 입력 페이지로 이동
      // 활성화 상태가 아닌 경우 : 대기 페이지로 이동
      if (user && resCompany.data.msg === "no data") {
        const companyInfo = cookies.get("info");
        companyInfo.status = "no data";
        cookies.set("info", companyInfo);
        navigate("/company");
        return;
      }

      // 사용자 활성화 여부 확인
      const resUser = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/dashboard/user?company=${resCompany.data.data.company_index}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      // cookie 정보 업데이트
      // const companyInfo = cookies.get("info");
      const companyInfo = {};
      companyInfo.t = resCompany.data.data.gst;
      companyInfo.c = resCompany.data.data.credit_purchase_enabled;
      companyInfo.a = resCompany.data.data.is_active;
      companyInfo.g = resCompany.data.data.purchase_grade;
      companyInfo.ua = resUser.data.result;
      // companyInfo.g=
      cookies.set("status", companyInfo);

      navigate("/", { replace: true });
    } catch (err) {
      alert(
        " 아이디 또는 비밀번호를 잘못 입력했습니다. \n 입력하신 내용을 다시 확인해주세요."
      );
    }
  };

  useEffect(() => {
    // If the user is logged in, don't show the login form
    if (!isLoading) {
      if (user) {
        // Redirect to the profile page
        navigate("/", { replace: true });
      }
    }
  }, []);

  return (
    <>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Row className="w-100">
          <Col>
            <div className="text-center p-5">
              <img src={slogan} style={{ width: "100%" }} alt="slogan" />
            </div>
          </Col>
          <Col className="text-center">
            <div className="p-5 border rounded">
              <img src={logo} alt="로고" />

              <Form
                className="register-form text-start mt-4"
                onSubmit={handleSubmit}
              >
                <label>이메일</label>
                <InputGroup className="form-group-no-border">
                  <Input
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
                <label className="mt-3">비밀번호</label>
                <InputGroup className="form-group-no-border">
                  <Input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </InputGroup>
                <Button
                  block
                  className="btn-round, mt-3"
                  color="danger"
                  type="submit"
                  size="lg"
                >
                  로그인
                </Button>
              </Form>
              <div className="forgot mt-3">
                <Row>
                  <Col>
                    <Link to="/signup">회원가입</Link>
                  </Col>
                  <Col>
                    <Link to="/forgot-password">비밀번호 재설정</Link>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="p-2"></div>
      <Footer />
    </>
  );
}
