import React from "react";
import { Row, Col, Table } from "reactstrap";
import Moment from "react-moment";
import { orderDetail } from "../../components/print/orderDetail";
import { orderStatus } from "../../utils/ConvertWord";

function ExcelOrderList({ excelOrders }) {
  return (
    <>
      <Row className="mt-5">
        <Col>
          <h5 className="mb-3">엑셀 주문 리스트</h5>
          {excelOrders.length > 0 ? (
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center">번호</th>
                  <th className="text-center">주문번호</th>
                  <th className="text-center">등록일</th>
                  <th className="text-center">출고일</th>
                  <th className="text-center">이름</th>
                  <th className="text-center">무게(kg)</th>
                  <th className="text-center">배송료</th>
                  {excelOrders[0].transaction_info ? (
                    <th className="text-center">총금액(제품+배송료)</th>
                  ) : (
                    <th className="text-center">주문상태</th>
                  )}
                  <th className="text-center">운송장번호</th>
                </tr>
              </thead>
              <tbody>
                {excelOrders.map((order, idx) => (
                  <tr key={idx}>
                    <th scope="row" className="text-center">
                      {idx + 1}
                    </th>
                    <td>
                      <span
                        onClick={() => orderDetail(order.order_no)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        {order.order_no}
                      </span>
                    </td>
                    <td className="text-center">
                      <Moment format="YYYY-MM-DD">{order.created_at}</Moment>
                    </td>
                    <td className="text-center">
                      {order.delivery_at && (
                        <Moment format="YYYY-MM-DD">{order.delivery_at}</Moment>
                      )}
                    </td>
                    <td className="text-center">{order.customer_name} </td>
                    <td className="text-center">{order.weight} </td>
                    <td className="text-center">
                      {order.delivery_fee && <>${order.delivery_fee}</>}
                    </td>

                    {order.transaction_info ? (
                      <td className="text-center">
                        $
                        {(
                          order.transaction_info.credit +
                          order.transaction_info.point +
                          order.transaction_info.deposit
                        ).toFixed(2)}
                        <br />
                        {order.transaction_info.deposit > 0.0 && (
                          <li>
                            선불충전금 : $
                            {order.transaction_info.deposit.toFixed(2)}
                          </li>
                        )}
                        {order.transaction_info.point > 0.0 && (
                          <li>
                            포인트 : ${order.transaction_info.point.toFixed(2)}
                          </li>
                        )}
                        {order.transaction_info.credit > 0.0 && (
                          <li>
                            크레딧 : ${order.transaction_info.credit.toFixed(2)}
                          </li>
                        )}
                      </td>
                    ) : (
                      <td className="text-center">
                        {orderStatus(order.order_status)}
                      </td>
                    )}

                    <td className="text-center">
                      {order.hawb !== "취소" && (
                        <a
                          href={`https://www.epantos.com/ecp/web/pr/dt/popup/dlvChaseInqPopup.do?locale=ko&quickNo=${order.hawb}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {order.hawb}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="border text-center p-5">엑셀 주문이 없습니다.</div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default ExcelOrderList;
