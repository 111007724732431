import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
} from "reactstrap";
import { addCommasToNumber } from "../../../utils/AboutNumber";
import RequestDeposit from "./RequestDeposit";
import RequestDepositConfirmed from "./RequestDepositConfirmed";
import axios from "axios";
import { Cookies } from "react-cookie";
import RequestDepositUsed from "./RequestDepositUesd";

const cookies = new Cookies();

function AdminDeposit({ isActive }) {
  const [activeTab, setActiveTab] = useState("선불충전금 입금 확인 대상");
  const [summary, setSummary] = useState({
    requestCnt: 0,
    totalAmount: 0,
    monthAmount: 0,
  });
  const today = new Date();

  useEffect(() => {
    // 선불충전금 요약 정보 가져오기
    if (isActive) {
      getSummary();
    }
  }, [isActive]);

  const getSummary = async () => {
    try {
      const resSummary = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/deposit/summary`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      setSummary({
        requestCnt: resSummary.data[0].count
          ? parseInt(resSummary.data[0].count)
          : 0,
        totalAmount: resSummary.data[0].total ? resSummary.data[0].total : 0,
        monthAmount: resSummary.data[0].month ? resSummary.data[0].month : 0,
      });
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  return (
    <>
      <Row className="mb-5">
        <Col>
          <div
            className="border p-3 radios text-center"
            style={{ height: "100px" }}
          >
            입금확인 대상건수 : {summary.requestCnt}건
          </div>
        </Col>
        <Col>
          {" "}
          <div
            className="border p-3 radios text-center"
            style={{ height: "100px" }}
          >
            선불충전금 누적금액 : ${addCommasToNumber(summary.totalAmount)}
            <br />
            {today.getMonth() + 1}월 선불충전금액 :$
            {addCommasToNumber(summary.monthAmount)}
          </div>
        </Col>
      </Row>

      <Nav tabs className="mb-3">
        {[
          "선불충전금 입금 확인 대상",
          "선불충전금 입금",
          "선불충전금 사용",
        ].map((tab) => (
          <NavItem key={tab} className="bg-light">
            <NavLink
              className={activeTab === tab ? "active" : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Container style={{ marginLeft: "0px" }}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="선불충전금 입금 확인 대상" className="border-0">
            <RequestDeposit
              getSummary={getSummary}
              isActive={activeTab === "선불충전금 입금 확인 대상"}
            />
          </TabPane>
          <TabPane tabId="선불충전금 입금" className="border-0">
            <RequestDepositConfirmed
              isActive={activeTab === "선불충전금 입금"}
            />
          </TabPane>
          <TabPane tabId="선불충전금 사용" className="border-0">
            <RequestDepositUsed isActive={activeTab === "선불충전금 사용"} />
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
}

export default AdminDeposit;
