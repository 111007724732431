import React, { useEffect } from "react";
import NoSidebarLayout from "../components/layout/NoSidebarLayout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

function UserPending() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkIsActive = async () => {
      try {
        // 도매업체 정보 가져오기
        const resActive = await axios.get(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/dashboard/user/isactive`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );

        if (resActive.data.result === true) {
          navigate("/");
          return;
        }
      } catch (error) {
        console.error("데이터를 불러오는데 실패했습니다", error);
      }
    };
    checkIsActive();
  }, []);

  return (
    <NoSidebarLayout>
      <div style={styles.container}>
        <h1 style={styles.heading}>사용자 계정 승인 대기 중입니다</h1>
        <p style={styles.message}>현재 운영자가 계정 승인을 검토 중입니다.</p>
        <p style={styles.message}>
          계정이 승인되면 이메일로 안내 드리겠습니다. 궁금한 사항이 있으시면
          고객 지원팀(info@megamall.nz)에 문의해 주세요.
        </p>
        <div style={styles.spinner}></div>
      </div>
    </NoSidebarLayout>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
  },
  heading: {
    fontSize: "2rem",
    marginBottom: "20px",
    color: "#333",
  },
  message: {
    fontSize: "1.2rem",
    marginBottom: "15px",
    color: "#555",
  },
  spinner: {
    marginTop: "20px",
    width: "50px",
    height: "50px",
    border: "5px solid rgba(0, 0, 0, 0.1)",
    borderTop: "5px solid #333",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

export default UserPending;
