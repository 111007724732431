import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

const CompanyForm = ({
  fetchCompanies,
  selectedCompany,
  setSelectedCompany,
  setShow,
}) => {
  const [companyName, setCompanyName] = useState("");
  const [wholesaler, setWholesaler] = useState("");
  const [managerName, setManagerName] = useState("");
  const [managerTel, setManagerTel] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [grade, setGrade] = useState("");
  const [creditPurchaseEnabled, setCreditPurchaseEnabled] = useState(false);
  const [gstIncluded, setGstIncluded] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [memo, setMemo] = useState("");
  const [address, setAddress] = useState("");
  const [deliveryFee, setDeliveryFee] = useState({
    newzealand: 12,
    australia: 12,
    hiwell: 12,
    vitazone: 12,
  });

  useEffect(() => {
    if (selectedCompany) {
      setCompanyName(selectedCompany.company_name);
      setWholesaler(selectedCompany.wholesaler);
      setManagerName(
        selectedCompany.manager_name !== null
          ? selectedCompany.manager_name
          : ""
      );
      setManagerTel(
        selectedCompany.manager_tel !== null ? selectedCompany.manager_tel : ""
      );
      setManagerEmail(selectedCompany.manager_email);
      setGrade(selectedCompany.purchase_grade);
      setMemo(
        selectedCompany.company_memo !== null
          ? selectedCompany.company_memo
          : ""
      );
      setCreditPurchaseEnabled(selectedCompany.credit_purchase_enabled);
      setGstIncluded(selectedCompany.gst);
      setIsActive(selectedCompany.is_active);
      setAddress(
        selectedCompany.company_address !== null
          ? selectedCompany.company_address
          : ""
      );
      setDeliveryFee({
        newzealand:
          selectedCompany.delivery_fee.newzealand !== null
            ? selectedCompany.delivery_fee.newzealand
            : 12,
        australia:
          selectedCompany.delivery_fee.australia !== null
            ? selectedCompany.delivery_fee.australia
            : 12,
        hiwell:
          selectedCompany.delivery_fee.hiwell !== null
            ? selectedCompany.delivery_fee.hiwell
            : 12,
        vitazone:
          selectedCompany.delivery_fee.vitazone !== null
            ? selectedCompany.delivery_fee.vitazone
            : 12,
      });
    } else {
      setCompanyName("");
      setWholesaler("");
      setManagerName("");
      setManagerTel("");
      setManagerEmail("");
      setGrade("");
      setCreditPurchaseEnabled(false);
      setGstIncluded(false);
      setIsActive(false);
      setMemo("");
      setAddress("");
      setDeliveryFee({
        newzealand: 12,
        australia: 12,
        hiwell: 12,
        vitazone: 12,
      });
    }
  }, [selectedCompany]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedCompany) {
        await axios.put(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/company/${selectedCompany.company_index}`,
          {
            companyName,
            wholesaler,
            managerName,
            managerTel,
            managerEmail,
            grade,
            creditPurchaseEnabled,
            gstIncluded,
            isActive,
            memo,
            address,
            deliveryFee,
          },
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/company`,
          {
            companyName,
            wholesaler,
            managerName,
            managerTel,
            managerEmail,
            grade,
            creditPurchaseEnabled,
            gstIncluded,
            isActive,
            memo,
            address,
            deliveryFee,
          },
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );
      }
      fetchCompanies();
      setCompanyName("");
      setWholesaler("");
      setManagerName("");
      setManagerTel("");
      setManagerEmail("");
      setGrade("");
      setCreditPurchaseEnabled(false);
      setGstIncluded(false);
      setIsActive(false);
      setMemo("");
      setAddress("");
      setSelectedCompany(null);
      setShow(false);
      setDeliveryFee({
        newzealand: 12,
        australia: 12,
        hiwell: 12,
        vitazone: 12,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeDeliveryFee = (e) => {
    const { name, value } = e.target;
    setDeliveryFee((prevDeliveryFee) => ({
      ...prevDeliveryFee,
      [name]: value,
    }));
  };

  return (
    <Container>
      <Row>
        <Col md={{ size: 8, offset: 2 }}>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="wholesaler">업체명(주문서 작성용)</Label>
                  <Input
                    disabled="true"
                    type="text"
                    name="wholesaler"
                    id="wholesaler"
                    value={wholesaler}
                    onChange={(e) => setWholesaler(e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="companyName">회사 이름(XERO용 사업자명)</Label>
                  <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="managerName">담당자명</Label>
                  <Input
                    type="text"
                    name="managerName"
                    id="managerName"
                    value={managerName}
                    onChange={(e) => setManagerName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="managerTel">전화번호</Label>
                  <Input
                    type="text"
                    name="managerTel"
                    id="managerTel"
                    value={managerTel}
                    onChange={(e) => setManagerTel(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="managerEmail">이메일</Label>
                  <Input
                    type="text"
                    name="managerEmail"
                    id="managerEmail"
                    value={managerEmail}
                    onChange={(e) => setManagerEmail(e.target.value)}
                    required
                  />
                </FormGroup>{" "}
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="grade">그룹</Label>
                  <Input
                    type="select"
                    name="grade"
                    id="grade"
                    value={grade}
                    onChange={(e) => setGrade(e.target.value)}
                    required
                  >
                    <option value="">선택...</option>
                    <option value="korg">KORG</option>
                    <option value="kor">KOR</option>
                    <option value="locaa">LOCAA</option>
                    <option value="loca">LOCA</option>
                  </Input>
                </FormGroup>{" "}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="deliveryFee">신용구매</Label>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          <Input
                            type="radio"
                            name="creditEnabled"
                            checked={creditPurchaseEnabled === true}
                            onChange={(e) => setCreditPurchaseEnabled(true)}
                          />{" "}
                          가능
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      {" "}
                      <FormGroup>
                        <Label>
                          <Input
                            type="radio"
                            name="creditEnabled"
                            checked={creditPurchaseEnabled === false}
                            onChange={(e) => setCreditPurchaseEnabled(false)}
                          />{" "}
                          불가능
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="gst">인보이스 GST</Label>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          <Input
                            type="radio"
                            name="gst"
                            checked={gstIncluded === true}
                            onChange={(e) => setGstIncluded(true)}
                          />{" "}
                          포함
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      {" "}
                      <FormGroup>
                        <Label>
                          <Input
                            type="radio"
                            name="gst"
                            checked={gstIncluded === false}
                            onChange={(e) => setGstIncluded(false)}
                          />{" "}
                          미포함
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="address">주소</Label>
                  <Input
                    type="address"
                    name="address"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="memo">메모</Label>
                  <Input
                    type="memo"
                    name="memo"
                    id="memo"
                    value={memo}
                    onChange={(e) => setMemo(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="gst">계정 활성화</Label>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>
                          <Input
                            type="radio"
                            name="is_active"
                            checked={isActive === true}
                            onChange={(e) => setIsActive(true)}
                          />{" "}
                          활성
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>
                          <Input
                            type="radio"
                            name="is_active"
                            checked={isActive === false}
                            onChange={(e) => setIsActive(false)}
                          />{" "}
                          비활성
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="deliveryFee">배송료 기준</Label>
                  <Row>
                    <Col>
                      <FormGroup className="row align-items-center">
                        <Label for="memo" className="col-sm-4">
                          뉴질랜드
                        </Label>
                        <div className="col-sm-8">
                          <Input
                            type="number"
                            name="newzealand"
                            step={0.01}
                            id="newzealand"
                            value={deliveryFee["newzealand"]}
                            onChange={(e) => handleChangeDeliveryFee(e)}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="row align-items-center">
                        <Label for="memo" className="col-sm-4">
                          호주
                        </Label>
                        <div className="col-sm-8">
                          <Input
                            type="number"
                            name="australia"
                            step={0.01}
                            id="australia"
                            value={deliveryFee["australia"]}
                            onChange={(e) => handleChangeDeliveryFee(e)}
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="row align-items-center">
                        <Label for="memo" className="col-sm-4">
                          하이웰
                        </Label>
                        <div className="col-sm-8">
                          <Input
                            type="number"
                            name="hiwell"
                            step={0.01}
                            id="hiwell"
                            value={deliveryFee["hiwell"]}
                            onChange={(e) => handleChangeDeliveryFee(e)}
                          />
                        </div>
                      </FormGroup>{" "}
                      <FormGroup className="row align-items-center">
                        <Label for="memo" className="col-sm-4">
                          비타민존
                        </Label>
                        <div className="col-sm-8">
                          <Input
                            type="number"
                            name="vitazone"
                            step={0.01}
                            id="vitazone"
                            value={deliveryFee["vitazone"]}
                            onChange={(e) => handleChangeDeliveryFee(e)}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Button type="submit" color="primary">
              도매업체 {selectedCompany ? "정보 수정" : "추가"}
            </Button>{" "}
            <Button color="secondary" onClick={() => setShow(false)}>
              취소
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CompanyForm;
