import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import TopMenu from "./TopMenu";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export default function NormalLayout({ children }) {
  const { user, isLoading, signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    checkActivation();
  }, []);

  const checkActivation = () => {
    if (!isLoading) {
      if (!user) {
        navigate("/login", { replace: true });
        return;
      }

      if (cookies.get("sdtatus")) {
        if (cookies.get("status").a === false) {
          navigate("/pending", { replace: true });
          return;
        }

        if (cookies.get("status").ua === false) {
          navigate("/userpending", { replace: true });
          return;
        }
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(); // AWS Cognito 로그아웃
      cookies.remove("info");
      cookies.remove("balance");
      navigate("/login"); // 로그인 페이지로 리다이렉션
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Header onSignOut={handleSignOut} />
      {/* 모바일 메뉴 (상단 햄버거) */}
      <div className="d-md-none">
        <TopMenu onSignOut={handleSignOut} />
      </div>

      <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
        {/* Sidebar */}
        <div
          className="d-none d-md-block"
          style={{ width: "180px", backgroundColor: "#f8f9fa", height: "100%" }}
        >
          <Sidebar />
        </div>
        {/* Main Content */}
        <div style={{ flex: 1, padding: "20px" }}>{children}</div>
      </div>
      <Footer />
    </div>
  );
}
