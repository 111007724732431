import { useState } from "react";
import {
  signUp,
  confirmSignUp,
  resendConfirmationCode,
} from "../components/auth/auth";
import {
  Container,
  Alert,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Footer from "../components/layout/Footer";
import logo from "../../src/assets/img/logo.png";
import slogan from "../../src/assets/img/img_slogan.svg";

export default function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [confirmSuccess, setConfirmSuccess] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await signUp(email, password, companyName);

      setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleConfirmSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await confirmSignUp(email, code);
      alert("메가몰 회원가입이 완료되었습니다.");
      setConfirmSuccess(true);
      navigate("/login");
    } catch (err) {
      alert(err.message);
      // setError(err.message);
    }
  };

  const handleResend = async () => {
    try {
      await resendConfirmationCode(email);
      alert("코드 재발송 완료! 이메일을 확인해 주세요.");
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  if (success) {
    return (
      <Container className="login-container">
        <div>
          <div>
            <h2>메가몰 회원가입</h2>
            <p>이메일에서 6자리 승인코드를 확인 후 입력해 주세요.</p>
            <Form onSubmit={handleConfirmSubmit}>
              <FormGroup>
                <Input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="확인코드 숫자6자리"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  required
                />
              </FormGroup>
              <Button type="submit" color="primary" block>
                {" "}
                확인
              </Button>
            </Form>

            {error && <p>{error}</p>}
          </div>
        </div>
      </Container>
    );
  }

  if (confirmSuccess) {
    return (
      <div>
        <h2>회원가입 성공</h2>
        <p>Kia Ora 로그인 후 이용하시기 바랍니다.</p>
        <Link to="/login">로그인</Link>
      </div>
    );
  }

  return (
    <>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Row className="w-100">
          <Col>
            <div className="text-center p-5">
              <a href="/">
                <img src={slogan} style={{ width: "100%" }} alt="slogan" />
              </a>
            </div>
          </Col>
          <Col className="text-center">
            <div className="p-5 square border rounded">
              <img src={logo} alt="로고" />
              {error && <Alert className="mt-3">{error}</Alert>}
              <Form
                onSubmit={handleSubmit}
                className="register-form text-start mt-4"
              >
                <FormGroup>
                  <Label for="email">이메일</Label>
                  <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </FormGroup>
                <Label for="password">비밀번호</Label>
                <FormGroup>
                  <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="companyName">업체명</Label>
                  <Input
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder="업체명"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </FormGroup>
                <Button type="submit" block color="primary" size="lg">
                  회원가입
                </Button>

                <div className="p-2 text-center">
                  {" "}
                  <Link to="/login">로그인</Link>
                </div>

                {error ===
                  "An account with the given email already exists." && (
                  <div className="text-center">
                    <p
                      onClick={() => handleResend()}
                      style={{ cursor: "pointer" }}
                      className="text-primary"
                    >
                      이미 등록된 경우 이메일 계정 확인 후 확인코드를
                      입력해주세요.
                    </p>
                  </div>
                )}
              </Form>
              {/* {error && <p>{error}</p>} */}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="p-2"></div>
      <Footer />
    </>
  );
}
