import React, { useState } from "react";
import { Table, Tooltip } from "reactstrap";
import { orderDetail } from "../print/orderDetail";
import Moment from "react-moment";

function OrderDoneStatusList({ data }) {
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (receiver) => {
    setTooltipOpen({
      ...tooltipOpen,
      [receiver]: !tooltipOpen[receiver],
    });
  };

  // 주문건별 총 상품금액 계산함수
  const genAmount = (items, delivery_fee) => {
    let amount = 0.0;

    items.map((item) => {
      let price = convertPriceFormat(
        item.productCode,
        item.unitPrice,
        item.qauntity
      ).replace("$", "");
      if (!isNaN(price)) {
        amount += parseFloat(price);
      }
    });
    // 배송료 추가
    amount += delivery_fee ? parseFloat(delivery_fee) : 0;

    return amount.toFixed(2);
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    let resValue = null;
    let pcode = productCode.toUpperCase();

    // 예외처리 코드 : BRING, CHEMIST, MART, PROMO, SPECIAL, 공백
    if (pcode === "BRING" || pcode === "PROMO") {
      resValue = "$0";
    } else if (
      pcode === "CHEMIST" ||
      pcode === "MART" ||
      pcode === "SPECIAL" ||
      pcode === ""
    ) {
      resValue = "미정";
    } else {
      resValue =
        "$" +
        parseFloat(unitPrice * qauntity)
          .toFixed(2)
          .toString();
    }

    return resValue;
  };

  return (
    <div>
      <Table hover bordered>
        <thead>
          <tr className="table-primary">
            <th className="text-center p-2 border">이름</th>
            <th className="text-center p-2 border">무게</th>
            <th className="text-center p-2 border">배송료</th>
            <th className="text-center p-2 border">총액</th>
            <th className="text-center p-2 border">운송장번호</th>
            <th className="text-center p-2 border">등록일</th>
            <th className="text-center p-2 border">출고일</th>
            <th className="text-center p-2 border">요청메모</th>
            <th className="text-center p-2 border">배송메모</th>
            <th className="text-center p-2 border">주문상품</th>

            <th className="text-center p-2 border">쇼핑몰주문번호</th>
            <th className="text-center p-2 border">출고지</th>
          </tr>
        </thead>
        <tbody>
          {data.map((o, idx) => {
            return (
              <tr key={idx}>
                <td
                  className="text-center cell"
                  style={{ cursor: "pointer", width: "80px" }}
                >
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => orderDetail(o.order_no)}
                  >
                    <span id={`Tooltip-${o.order_index}`}>
                      {" "}
                      {o.customer_name}
                    </span>
                    <Tooltip
                      placement="top"
                      isOpen={tooltipOpen[o.order_index]}
                      target={`Tooltip-${o.order_index}`}
                      toggle={() => toggleTooltip(o.order_index)}
                    >
                      <div className="text-start">
                        <li>통관부호 : {o.pcc}</li>
                        <li>전화번호 : {o.customer_tel}</li>
                        <li>모바일 : {o.customer_mobile}</li>
                        <li>
                          주소 : {o.address}, #{o.zip}
                        </li>
                      </div>
                    </Tooltip>
                  </span>
                </td>
                <td className="text-center">{o.weight}</td>
                <td className="text-center">{o.delivery_fee}</td>
                <td className="text-center">
                  ${genAmount(o.order_product, o.delivery_fee)}
                </td>
                <td className="text-center">
                  <a
                    href={`https://www.epantos.com/ecp/web/pr/dt/popup/dlvChaseInqPopup.do?locale=ko&quickNo=${o.hawb}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {o.hawb}
                  </a>
                </td>
                <td className="text-center">
                  <Moment format="YYYY-MM-DD">{o.created_at}</Moment>
                </td>
                <td className="text-center">
                  <Moment format="YYYY-MM-DD">{o.delivery_at}</Moment>
                </td>
                <td className="text-center cell">{o.shipper_memo}</td>
                {/* <td className="text-center" style={{ cursor: "pointer" }}>
                  {" "}
                 
                </td> */}
                <td>{o.delivery_memo}</td>
                <td style={{ padding: "0px" }}>
                  <Table bordered style={{ margin: "0px", width: "100%" }}>
                    <tbody>
                      {o.order_product.map((i, index) => {
                        return (
                          <tr key={index}>
                            <td
                              className="text-center"
                              style={{ width: "70px" }}
                            >
                              {i.productCode}
                            </td>

                            <td className="cell">{i.productName}</td>
                            <td
                              style={{ width: "60px" }}
                              className="text-center"
                            >
                              {i.qauntity}개
                            </td>
                            <td
                              style={{ width: "70px" }}
                              className="text-center"
                            >
                              {convertPriceFormat(
                                i.productCode,
                                i.unitPrice,
                                i.qauntity
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </td>

                <td className="text-center">{o.wholesaler_order_no}</td>
                <td className="text-center">{o.departure_place}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default OrderDoneStatusList;
