import React, { useContext } from "react";
import { Button, Navbar, Nav, NavItem } from "reactstrap";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom/dist";
import logo from "../../../src/assets/img/logo.png";

const Header = ({ onSignOut }) => {
  const { user } = useContext(AuthContext);

  const openManual = () => {
    window.open("/manual", "_blank", "width=1200,height=800");
  };

  return (
    <Navbar
      expand="ls"
      className="p-3"
      style={{ background: "#343a40", borderColor: "#343a40" }}
    >
      {/* <NavbarBrand className="text-light">
      
      </NavbarBrand> */}
      <Link
        className="bold_600"
        to="/"
        style={{
          color: "whitesmoke",
          textDecoration: "none",
          fontSize: "18px",
        }}
      >
        {/* Megamall */}
        <img src={logo} alt="로고" width="120px" />
      </Link>
      <Nav className="ml-auto" navbar>
        <NavItem>
          <span className="p-3 text-white">
            Kia Ora! {user && user.profile && `${user.profile}(${user.email})`}
            {process.env.REACT_APP_MODE === "로컬" && " [로컬모드]"}
          </span>
          <Button color="primary" onClick={openManual}>
            사용자 매뉴얼
          </Button>{" "}
          <Button color="danger" onClick={onSignOut}>
            로그아웃
          </Button>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Header;
