import React, { useState, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { Cookies } from "react-cookie";
import { pickupOrderStatus } from "../../utils/ConvertWord";
import { useParams } from "react-router-dom";

// 이 파라미터로 받은 스테이터스 값을 가져와줘야함

const cookies = new Cookies();

function PickupList() {
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수
  const [count, setCount] = useState(0);
  let { status } = useParams();

  const [selectedStatuses, setSelectedStatuses] = useState([
    "REGISTERED",
    "DONE",
    "READY",
    "PROCESSING",
    "CANCEL",
  ]);

  const [dropdownOpen, setDropdownOpen] = useState(false); // 드롭다운의 열림/닫힘 상태
  const [pageSize, setPageSize] = useState(10); // 페이지당 노출수 설정

  const statusList = [
    { name: "픽업주문등록", status: "REGISTERED" },
    { name: "준비 중", status: "PROCESSING" },
    { name: "제품 준비 완료", status: "READY" },
    { name: "완료", status: "DONE" },
    { name: "취소", status: "CANCEL" },
  ];

  const toggle = () => setDropdownOpen((prevState) => !prevState); // 드롭다운 상태 토글

  // 드롭다운에서 항목 선택 시, 페이지당 노출수 업데이트
  const selectPageSize = (size) => {
    setCurrentPage(0);
    setPageSize(size);
  };

  useEffect(() => {
    if (status) {
      setSelectedStatuses([status.toUpperCase()]);
    }
  }, []);

  useEffect(() => {
    pickupListData();
  }, [currentPage, pageSize, selectedStatuses]);

  const pickupListData = async () => {
    try {
      // 업로드 목록 가져오기
      const encodedWholesaler = encodeURIComponent(cookies.get("info").profile);

      // if (selectedStatuses.length === 0) {
      //   alert("최소 1개 이상 주문상태를 선택해 주세요.");
      //   return;
      // }

      const resList = await axios.get(
        `${
          process.env.REACT_APP_WHOLESALE_API_URL
        }/pickup/list?page=${currentPage}&limit=${pageSize}&wholesaler=${encodedWholesaler}&statuses=${selectedStatuses.join(
          ","
        )}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (resList.data.result === "failed") {
        setData(null);
        setCount(0);
        setPageCount(0);
      } else {
        setData(resList.data.data.list);
        setCount(resList.data.data.count.count);
        setPageCount(Math.ceil(resList.data.data.count.count / pageSize));
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
      setData(null);
    }
  };

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (p) => {
    setCurrentPage(p.selected);
  };

  // 주문목록 체크박스 변경 처리
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedStatuses((prevStatuses) => {
      if (checked) {
        return [...prevStatuses, value];
      } else {
        return prevStatuses.filter((status) => status !== value);
      }
    });
  };

  // 주문 삭제하기
  const handleDelete = (no, wholesaler) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(`${no} 픽업주문을 삭제하시겠습니까?`);

    if (isConfirmed) {
      deleteOrder(no, wholesaler);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("사용자가 삭제를 취소했습니다.");
    }
  };

  const deleteOrder = async (pickupOrderIndex, wholesaler) => {
    try {
      const resList = await axios.delete(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/pickup?pickupOrderIndex=${pickupOrderIndex}&wholesaler=${wholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (resList.data.result === "success") {
        pickupListData();
      } else {
        console.error("주문건을 삭제하는데 실패했습니다");
      }
    } catch (error) {
      console.error("주문건을 삭제하는데 실패했습니다", error);
    }
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    let resValue =
      "$" + (parseFloat(unitPrice) * qauntity).toFixed(2).toString();

    return resValue;
  };

  return (
    <NormalLayout>
      <div>
        <h3>픽업주문목록</h3>
      </div>
      <div className="mt-3">
        <div className="mb-3">
          {/* <div className="text-center p-5  mb-3 border">검색 설정</div>{" "} */}
          <Row>
            <Col>
              {" "}
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret color="dark">
                  페이지당 노출 수: {pageSize}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => selectPageSize(5)}>
                    5개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(10)}>
                    10개
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col className="text-end">
              <div>
                {statusList.map((status) => (
                  <FormGroup check inline key={status.status}>
                    <Label check>
                      <Input
                        type="checkbox"
                        value={status.status}
                        checked={selectedStatuses.includes(status.status)}
                        onChange={handleCheckboxChange}
                      />
                      {status.name}
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </Col>
          </Row>
        </div>
        {data ? (
          <>
            <div className="border p-3">
              <Table hover bordered>
                <thead>
                  <tr className="table-primary">
                    <th className="text-center">등록일</th>
                    <th className="text-center">주문제품</th>
                    <th className="text-center">메모</th>

                    <th className="text-center">픽업상태</th>
                    <th className="text-center">기능</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((order, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="text-center">
                          <Moment format="YYYY/MM/DD HH:mm">
                            {order.created_at}
                          </Moment>
                        </td>

                        <td style={{ padding: "0px" }}>
                          <Table
                            bordered
                            style={{ margin: "0px", width: "100%" }}
                          >
                            <tbody>
                              {order.order_product.map((i, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="cell70">{i.productCode}</td>

                                    <td className="cell">{i.productName}</td>
                                    <td
                                      style={{ width: "60px" }}
                                      className="text-center"
                                    >
                                      {i.qauntity}개
                                    </td>
                                    <td
                                      style={{ width: "70px" }}
                                      className="text-center"
                                    >
                                      {convertPriceFormat(
                                        i.productCode,
                                        i.unitPrice,
                                        i.qauntity
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </td>
                        <td className="text-center">{order.memo}</td>

                        <td className="text-center">
                          {pickupOrderStatus(order.pickup_status)}
                        </td>
                        <td className="text-center">
                          {order.pickup_status === "REGISTERED" && (
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() =>
                                handleDelete(
                                  order.pickup_order_no,
                                  order.wholesaler
                                )
                              }
                            >
                              삭제
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div
                className=""
                style={{ display: "flex", justifyContent: "center" }}
              >
                <ReactPaginate
                  previousLabel={"이전"}
                  nextLabel={"다음"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"item active "}
                  breakClassName={"item break-me "}
                  pageClassName={"item pagination-page "}
                  disabledClassName={"disabled-page"}
                />
              </div>
            </div>
          </>
        ) : (
          <Table hover bordered>
            <thead>
              <tr className="table-primary">
                <th className="text-center">등록일</th>
                <th className="text-center">주문제품</th>
                <th className="text-center">메모</th>
                <th className="text-center">픽업상태</th>
                <th className="text-center">기능</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={5} className="text-center">
                  픽업주문 내역이 없습니다.
                </td>
              </tr>
            </tbody>
          </Table>
        )}
      </div>
    </NormalLayout>
  );
}

export default PickupList;
