import React, { useState, useEffect } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { Cookies } from "react-cookie";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";

const cookies = new Cookies();

function PickupDone({ isActive }) {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState();
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수

  useEffect(() => {
    // 픽업주문 데이터 가져오기
    if (isActive) {
      getData();
    }
  }, [isActive, currentPage]);

  const getData = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup/order?status=DONE&page=${currentPage}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.msg !== "no data") {
        setData(resList.data.data.list);
        setFilteredOrders(resList.data.data.list);
        setCount(resList.data.data.count.count);
        setPageCount(Math.ceil(resList.data.data.count.count / 10));
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    let resValue = null;

    resValue =
      "$" +
      parseFloat(unitPrice * qauntity)
        .toFixed(2)
        .toString();

    return resValue;
  };

  return (
    <>
      {/* <div>
        <h3>
          픽업주문등록{" "}
          {data && data.length !== 0 ? <>({data.length}건)</> : <></>}
        </h3>
      </div> */}
      {/* <div className="p-3"></div> */}
      {filteredOrders ? (
        <>
          <div className="border p-3">
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center p-2 border">NO</th>
                  <th className="text-center p-2 border">주문일시</th>
                  <th className="text-center p-2 border">업체명</th>
                  <th className="text-center p-2 border">픽업주문번호</th>
                  <th className="text-center p-2 border">주문제품</th>
                  <th className="text-center p-2 border">메모</th>
                  <th className="text-center p-2 border">픽업 시간</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={"7"}>
                      주문 없음
                    </td>
                  </tr>
                )}
                {filteredOrders.map((o, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">{idx + 1}</td>
                      <td className="text-center">
                        <Moment format="YYYY/MM/DD HH:mm">
                          {o.created_at}
                        </Moment>
                      </td>
                      <td className="text-center">{o.wholesaler}</td>
                      <td className="text-center">{o.pickup_order_no}</td>
                      <td style={{ padding: "0px" }}>
                        <Table
                          bordered
                          style={{ margin: "0px", width: "100%" }}
                        >
                          <tbody>
                            {o.order_product.map((i, index) => {
                              return (
                                <tr key={index}>
                                  <td className="cell70 text-center">
                                    {i.productCode}
                                  </td>

                                  <td className="cell">{i.productName}</td>
                                  <td
                                    style={{ width: "60px" }}
                                    className="text-center"
                                  >
                                    {i.qauntity}개
                                  </td>
                                  <td
                                    style={{ width: "70px" }}
                                    className="text-center"
                                  >
                                    {convertPriceFormat(
                                      i.productCode,
                                      i.unitPrice,
                                      i.qauntity
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>
                      <td>{o.memo}</td>

                      <td className="text-center">
                        {o.pickup_at ? (
                          <Moment format="YYYY/MM/DD HH:mm">
                            {o.pickup_at}
                          </Moment>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReactPaginate
                previousLabel={"이전"}
                nextLabel={"다음"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"item active "}
                breakClassName={"item break-me "}
                pageClassName={"item pagination-page "}
                disabledClassName={"disabled-page"}
              />
            </div>
          </div>
        </>
      ) : (
        <>주문 데이터 없음</>
      )}
    </>
  );
}

export default PickupDone;
