import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from "react-moment";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Cookies } from "react-cookie";
import { addCommasToNumber } from "../../../utils/AboutNumber";
import { getPoint } from "../../../utils/PointRate";
import ReactPaginate from "react-paginate";
const cookies = new Cookies();

function RequestDeposit({ getSummary, isActive }) {
  const [list, setList] = useState([]);
  const [modal, setModal] = useState(false);
  const [currentDeposit, setCurrentDeposit] = useState({});
  const [memo, setMemo] = useState("");
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수

  useEffect(() => {
    // 선불충전금 요약 정보 가져오기
    if (isActive) {
      getList();
    }
  }, [isActive, currentPage]);

  const getList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/deposit/pending?page=${currentPage}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      setList(res.data.data.list);
      setCount(res.data.data.count.count);
      setPageCount(Math.ceil(res.data.data.count.count / 10));
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const toggle = () => {
    setModal(!modal);
    setMemo("");
  };

  const handleConfirmClick = (deposit) => {
    setCurrentDeposit(deposit);
    toggle();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // 관리자 선불충전금 입금처리
    try {
      const resList = await axios
        .put(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/deposit`,
          {
            deposit: currentDeposit,
            memo,
          },
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        )
        .then(() => {
          getSummary();
          getList();
          toggle();
          alert("선불충전금 처리를 완료했습니다.");
        });
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
      toggle();
      alert("선불충전금 처리에 문제가 있습니다.");
    }
  };

  return (
    <div>
      <Table hover bordered striped>
        <thead>
          <tr className="table-primary">
            <th className="text-center p-2 border">No</th>
            <th className="text-center p-2 border">등록일</th>
            <th className="text-center p-2 border">업체명</th>
            <th className="text-center p-2 border">금액</th>
            <th className="text-center p-2 border">적립 예정 포인트</th>
            <th className="text-center p-2 border">입금자명</th>
            <th className="text-center p-2 border">확인</th>
          </tr>
        </thead>
        <tbody>
          {list && list.length > 0 ? (
            list.map((l, idx) => {
              return (
                <tr key={idx}>
                  <td className="text-center">{idx + 1}</td>
                  <td className="text-center">
                    <Moment format="YYYY-MM-DD">{l.created_at}</Moment>
                  </td>
                  <td className="text-center">{l.wholesaler}</td>
                  <td className="text-center">
                    ${addCommasToNumber(l.amount)}
                  </td>
                  <td className="text-center">{l.bonus_point}</td>

                  <td className="text-center">{l.depositor}</td>
                  <td className="text-center">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => {
                        handleConfirmClick(l);
                      }}
                    >
                      확인
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={7} className="text-center">
                확인대상 목록 없음
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPaginate
          previousLabel={"이전"}
          nextLabel={"다음"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"item active "}
          breakClassName={"item break-me "}
          pageClassName={"item pagination-page "}
          disabledClassName={"disabled-page"}
        />
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>선불 충전금 입금 확인</ModalHeader>
        <ModalBody>
          <li>
            등록일 :{" "}
            {currentDeposit && (
              <Moment format="YYYY.MM.DD HH:mm">
                {currentDeposit.created_at}
              </Moment>
            )}
          </li>
          <li>업체명 : {currentDeposit && currentDeposit.wholesaler}</li>
          <li>
            {" "}
            금액 : $
            {currentDeposit &&
              currentDeposit.amount &&
              addCommasToNumber(currentDeposit.amount)}
          </li>
          <li>
            {" "}
            포인트 적립 :
            {currentDeposit &&
              currentDeposit.amount &&
              getPoint(currentDeposit.amount)}
          </li>

          <li>입금자명 : {currentDeposit && currentDeposit.depositor}</li>
          <br />

          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="memo">메모</Label>
              <Input
                type="textarea"
                name="memo"
                id="memo"
                value={memo}
                onChange={(e) => setMemo(e.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>
            확인
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            취소
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default RequestDeposit;
