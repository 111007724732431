import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import { handlePrint } from "../../print/receipt";
import { pickupOrderDetail } from "../../print/pickupOrderDetail";
import { addCommasToNumber } from "../../../utils/AboutNumber";
import Moment from "react-moment";
import { orderDetail } from "../../print/orderDetail";
import axios from "axios";
import { Cookies } from "react-cookie";
import ReactPaginate from "react-paginate";
const cookies = new Cookies();

function AdminCreditHistoryUsed({ isActive }) {
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수

  useEffect(() => {
    if (isActive) {
      //크레딧 사용 내역 가져오기
      getData();
    }
  }, [isActive, currentPage]);

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/credit/history/used?page=${currentPage}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      setData(res.data.data.list);
      setCount(res.data.data.count.count);
      setPageCount(Math.ceil(res.data.data.count.count / 10));
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  return (
    <>
      {data.length === 0 ? (
        <Table>
          <tbody>
            <tr>
              <td className="text-center">크레딧 사용 내역 없음</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <>
          <Table striped>
            <thead>
              <tr>
                <th>날짜</th>
                <th>업체</th>
                <th>내용</th>
                <th>크레딧 사용 금액</th>
                <th>메모</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <Moment format="YYYY-MM-DD">{item.created_at}</Moment>
                    </td>
                    <td>{item.wholesaler}</td>
                    <td>
                      {item.deposit_index && `선불 충전`}
                      {item.order_no && (
                        <span
                          onClick={() => orderDetail(item.order_no)}
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          엑셀주문
                          {/* ({item.order_no}) */}
                        </span>
                      )}
                      {item.pickup_order_no && (
                        <span
                          onClick={() =>
                            pickupOrderDetail(item.pickup_order_no)
                          }
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          픽업주문
                          {/* ({item.pickup_order_no}) */}
                        </span>
                      )}
                    </td>
                    <td
                      className={
                        item.amount >= 0 ? "text-success" : "text-danger"
                      }
                    >
                      {item.amount >= 0 ? "+" : ""}$
                      {item.amount !== null &&
                        addCommasToNumber(Math.abs(item.amount))}
                    </td>
                    <td>{item.transaction_memo}</td>
                  </tr>
                ))}
            </tbody>
          </Table>{" "}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReactPaginate
              previousLabel={"이전"}
              nextLabel={"다음"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"item active "}
              breakClassName={"item break-me "}
              pageClassName={"item pagination-page "}
              disabledClassName={"disabled-page"}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AdminCreditHistoryUsed;
