import React from "react";
import { Row, Col, Table } from "reactstrap";
import Moment from "react-moment";
import { pickupOrderDetail } from "../../components/print/pickupOrderDetail";
import { pickupOrderStatus } from "../../utils/ConvertWord";

function PickupOrderList({ pickupOrders }) {
  return (
    <>
      <Row className="mt-5">
        <Col>
          <h5 className="mb-3">픽업 주문 리스트</h5>
          {pickupOrders.length > 0 ? (
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center">번호</th>
                  <th className="text-center">주문번호</th>
                  <th className="text-center">메모</th>
                  <th className="text-center">등록일</th>
                  <th className="text-center">픽업일시</th>
                  {pickupOrders[0].transaction_info ? (
                    <th className="text-center">총금액</th>
                  ) : (
                    <th className="text-center">주문상태</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {pickupOrders.map((order, idx) => (
                  <tr key={idx}>
                    <th scope="row" className="text-center">
                      {idx + 1}
                    </th>
                    <td>
                      <span
                        onClick={() => pickupOrderDetail(order.pickup_order_no)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        {order.pickup_order_no}
                      </span>
                    </td>
                    <td>{order.memo}</td>
                    <td className="text-center">
                      <Moment format="YYYY-MM-DD">{order.created_at}</Moment>
                    </td>
                    <td className="text-center">
                      {order.pickup_at && (
                        <Moment format="YYYY-MM-DD">{order.pickup_at}</Moment>
                      )}
                    </td>
                    {order.transaction_info ? (
                      <td className="text-center">
                        $
                        {(
                          order.transaction_info.credit +
                          order.transaction_info.point +
                          order.transaction_info.deposit
                        ).toFixed(2)}
                        <br />
                        {order.transaction_info.deposit > 0.0 && (
                          <li>
                            선불충전금 : $
                            {order.transaction_info.deposit.toFixed(2)}
                          </li>
                        )}
                        {order.transaction_info.point > 0.0 && (
                          <li>
                            포인트 : ${order.transaction_info.point.toFixed(2)}
                          </li>
                        )}
                        {order.transaction_info.credit > 0.0 && (
                          <li>
                            크레딧 : ${order.transaction_info.credit.toFixed(2)}
                          </li>
                        )}
                      </td>
                    ) : (
                      <td className="text-center">
                        {pickupOrderStatus(order.pickup_status)}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="border text-center p-5">픽업 주문이 없습니다.</div>
          )}
        </Col>
      </Row>
    </>
  );
}

export default PickupOrderList;
