import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Button,
  Table,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

function AddPickupOrderForm({ setAddOrderInfo }) {
  const [wholesalers, setWholesalers] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedWholesaler, setSelectedWholesaler] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [newProducts, setNewProducts] = useState([]);
  const [newProductCodes, setNewProductCodes] = useState([]);

  useEffect(() => {
    getWholesaler();
  }, []);

  // 업체정보 또는 주문정보가 변경될 때마다 addOrderInfo 업데이트
  useEffect(() => {
    setAddOrderInfo({
      wholesaler: selectedWholesaler,
      products: newProducts,
    });
  }, [newProducts]);

  const getWholesaler = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/pickup/wholesaler`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.msg !== "no data") {
        console.log("업체정보", resList.data.data.list);
        setWholesalers(resList.data.data.list);
      } else {
        setWholesalers([]);
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const handleqauntityChange = (code, qauntity) => {
    if (qauntity <= 0) {
      alert("최소수량은 1개 이상입니다.");
      qauntity = 1;
    }
    setNewProducts(
      newProducts.map((p) => (p.product_code === code ? { ...p, qauntity } : p))
    );
  };

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const handleWholesalerSelect = (wholesaler) => {
    setSelectedWholesaler(wholesaler);
    setSearchTerm(wholesaler.wholesaler);
    setSearchVisible(true);
    setIsOpen(false);
    setNewProductCodes([]);
    setNewProducts([]);
  };

  const filteredwholesalers = wholesalers.filter((wholesaler) =>
    wholesaler.wholesaler.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsOpen(true);
  };

  const handleDeleteProduct = (code) => {
    setNewProducts(newProducts.filter((p) => p.product_code !== code));
    setNewProductCodes(newProductCodes.filter((item) => item !== code));
  };

  const handleProductSelect = (product) => {
    // setSelectedProducts([...selectedProducts, { ...product, qauntity: 1 }]);
    setNewProducts([...newProducts, { ...product, qauntity: 1 }]);
    setNewProductCodes([...newProductCodes, product.product_code]);
  };

  const performSearch = async (e) => {
    e.preventDefault();

    if (keyword === "") {
      setSearchResults([]);
      alert("검색어를 입력해 주세요. ");
      return;
    }

    // 검색
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/search/product/pickup?keyword=${keyword}&grade=${selectedWholesaler.purchase_grade}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      console.log("결과", resList.data);

      if (resList.status === 200) {
        setSearchResults(resList.data);
        console.log(resList.data);
        if (resList.data.length === 0) {
          alert(`입력하신 '${keyword}'에 대한 제품은 없습니다.`);
        }
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
      alert("업체선택 또는 키워드를 확인해주세요.");
    }
  };

  const handleReset = () => {
    setKeyword("");
    setSearchResults([]);
  };

  return (
    <div>
      {/* 업체 선택 */}
      <Table>
        <tbody>
          <tr>
            <td style={{ width: "200px", fontSize: "1.1rem" }}>
              1단계 : 업체선택
            </td>
            <td>
              {/* <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle caret color="primary">
                  {selectedWholesaler
                    ? selectedWholesaler.wholesaler
                    : "업체 선택"}
                </DropdownToggle>
                <DropdownMenu>
                  {wholesalers &&
                    wholesalers.map((w, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => handleWholesalerSelect(w)}
                      >
                        {w.wholesaler}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </Dropdown> */}
              <Input
                type="text"
                className="w-full p-2 border rounded-md"
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={() => setIsOpen(true)}
                placeholder="회사명을 입력하세요"
              />
              {isOpen && filteredwholesalers.length > 0 && (
                <div className="absolute w-full mt-1 bg-white border rounded-md shadow-lg max-h-48 overflow-y-auto">
                  {filteredwholesalers.map((wholesaler, index) => (
                    <div
                      key={index}
                      className="p-2 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleWholesalerSelect(wholesaler)}
                    >
                      <div className="font-medium">{wholesaler.name}</div>
                      <div className="text-sm text-gray-600">
                        {wholesaler.wholesaler}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </Table>

      {/* 제품 검색창 */}
      {searchVisible && (
        <Form onSubmit={performSearch}>
          <FormGroup>
            <Label
              for="keyword"
              style={{ fontSize: "1.1rem", marginLeft: "10px" }}
            >
              2단계 : 제품코드 또는 제품명으로 검색 하세요.
            </Label>
            <InputGroup className="text-center" style={{ width: "500px" }}>
              <Input
                type="text"
                id="keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="검색어를 입력해주세요."
              />
              <Button type="submit" color="primary">
                검색
              </Button>{" "}
              <Button
                style={{ marginLeft: "10px" }}
                color="warning"
                onClick={() => handleReset()}
              >
                검색 초기화
              </Button>
            </InputGroup>
          </FormGroup>
        </Form>
      )}

      {searchResults.length > 0 && (
        <>
          <Row>
            <Col>
              <h4 className="">"{keyword}" 검색 결과</h4>
            </Col>
          </Row>
          <Row className="mb-4 p-2">
            {searchResults.length > 0 && (
              <Table striped bordered hover className="mt-3 p-2">
                <thead>
                  <tr className="table-primary">
                    <th className="text-center">담기</th>
                    <th className="text-center">제품코드</th>
                    <th className="text-center">제품명</th>
                    <th className="text-center">공급가</th>
                    <th className="text-center">재고상태</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((product, idx) => (
                    <tr key={product.product_code}>
                      <td className="text-center">
                        {newProductCodes.includes(product.product_code) ? (
                          <Button
                            color="warning"
                            size="sm"
                            onClick={() =>
                              handleDeleteProduct(product.product_code)
                            }
                          >
                            완료
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => handleProductSelect(product)}
                          >
                            담기
                          </Button>
                        )}
                      </td>
                      <td className="text-center">{product.product_code}</td>
                      <td style={{ textAlign: "left" }}>
                        {product.en_name}
                        {product.point === "Y" && (
                          <span className="text-danger bold_800">[P]</span>
                        )}
                      </td>
                      <td className="text-center" style={{ width: "70px" }}>
                        {product.price}
                      </td>
                      <td
                        className="text-center"
                        style={{
                          width: "80px",
                          backgroundColor:
                            product.stock_status === "empty"
                              ? "lightgrey"
                              : product.stock_status,
                        }}
                      >
                        {product.stock_status === "empty" && "0"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Row>
        </>
      )}
      {newProducts && newProducts.length > 0 && (
        <div className="border p-2 mt-3 bg-light">
          <h4 className="mb-3 mt-2">주문 제품</h4>
          <Table>
            <thead>
              <tr>
                <th>제품명</th>
                <th>가격</th>
                <th>수량</th>
                <th>소계</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              {newProducts.map((product, idx) => (
                <tr key={idx}>
                  <td>{product.en_name}</td>
                  <td>${product.price}</td>
                  <td>
                    <Input
                      type="number"
                      value={product.qauntity}
                      onChange={(e) =>
                        handleqauntityChange(
                          product.product_code,
                          parseInt(e.target.value)
                        )
                      }
                      min={1}
                      step={1}
                      style={{ width: "70px" }}
                    />
                  </td>
                  <td>${(product.price * product.qauntity).toFixed(2)}</td>
                  <td>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDeleteProduct(product.product_code)}
                    >
                      X
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}

export default AddPickupOrderForm;
