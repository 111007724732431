import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

import { Cookies } from "react-cookie";
import AdminPointHistoryCharged from "./AdminPointHistoryCharged";
import AdminPointHistoryUsed from "./AdminPointHistoryUsed";
import { addCommasToNumber } from "../../../utils/AboutNumber";

const cookies = new Cookies();

function AdminPoint({ isActive }) {
  // const [modalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("포인트 적립");
  const [summary, setSummary] = useState({ deposit: 0, used: 0 });

  useEffect(() => {
    // 포인트 리스트 가져오기
    if (isActive) {
      getPoint();
    }
  }, [isActive]);

  const getPoint = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/point/summary`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      console.log("resList", res.data[0]);
      setSummary(res.data[0]);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  return (
    <>
      <Row className="mb-5">
        <Col>
          <div
            className="border p-3 radios text-center"
            style={{ height: "100px" }}
          >
            포인트 적립액 : {summary.deposit}
          </div>
        </Col>
        <Col>
          {" "}
          <div
            className="border p-3 radios text-center"
            style={{ height: "100px" }}
          >
            포인트 사용액 : {addCommasToNumber(summary.used)}
          </div>
        </Col>
      </Row>

      <Nav tabs className="mb-3 bg-light">
        {["포인트 적립", "포인트 사용"].map((tab) => (
          <NavItem key={tab}>
            <NavLink
              className={activeTab === tab ? "active" : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <Container style={{ marginLeft: "0px" }}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="포인트 적립" className="border-0">
            <AdminPointHistoryCharged isActive={activeTab === "포인트 적립"} />
          </TabPane>
          <TabPane tabId="포인트 사용" className="border-0">
            <AdminPointHistoryUsed isActive={activeTab === "포인트 사용"} />
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
}

export default AdminPoint;
