import React, { useState, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import axios from "axios";
import {
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ReactPaginate from "react-paginate";

import { Cookies } from "react-cookie";
import ExcelOrderSearchForm from "../../components/search/ExcelOrderSearchForm";
import OrderCancelStatusList from "../../components/tables/OrderCancelStatusList";

const cookies = new Cookies();

function Cancel() {
  const [data, setData] = useState(null);
  const [searchedData, setSearchedData] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수
  const [count, setCount] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false); // 드롭다운의 열림/닫힘 상태
  const [pageSize, setPageSize] = useState(10); // 페이지당 노출수 설정

  const toggle = () => setDropdownOpen((prevState) => !prevState); // 드롭다운 상태 토글

  // 드롭다운에서 항목 선택 시, 페이지당 노출수 업데이트
  const selectPageSize = (size) => {
    setCurrentPage(0);
    setPageSize(size);
  };

  useEffect(() => {
    const excelListData = async () => {
      try {
        const encodedWholesaler = encodeURIComponent(
          cookies.get("info").profile
        );
        const resList = await axios.get(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/manage/order?status=CANCEL&page=${currentPage}&limit=${pageSize}&wholesaler=${encodedWholesaler}`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );
        if (resList.data.result === "success") {
          setData(resList.data.data.list);
          setCount(resList.data.data.count.count);
          setPageCount(Math.ceil(resList.data.data.count.count / pageSize));
        }
      } catch (error) {
        console.error("데이터를 불러오는데 실패했습니다", error);
      }
    };
    excelListData();
  }, [currentPage, pageSize]);

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <NormalLayout>
      <div>
        <h3>주문취소 {count && count !== 0 ? <>({count}건)</> : <></>}</h3>
      </div>
      <div className="p-3"></div>
      {data ? (
        <>
          <div className="border p-3">
            <div className="mb-3">
              <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret color="dark">
                  페이지당 노출 수: {pageSize}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => selectPageSize(10)}>
                    10개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(30)}>
                    30개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(50)}>
                    50개
                  </DropdownItem>
                  <DropdownItem onClick={() => selectPageSize(100)}>
                    100개
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>{" "}
            </div>
            <div className="text-center mt-3">
              <ExcelOrderSearchForm
                target="CANCEL"
                setSearchedData={setSearchedData}
                setCount={setCount}
                token={cookies.get("info").token}
              />
            </div>
            {searchedData !== null ? (
              <OrderCancelStatusList data={searchedData} />
            ) : (
              <OrderCancelStatusList data={data} />
            )}

            {searchedData === null && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <ReactPaginate
                  previousLabel={"이전"}
                  nextLabel={"다음"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"item active "}
                  breakClassName={"item break-me "}
                  pageClassName={"item pagination-page "}
                  disabledClassName={"disabled-page"}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <Table hover bordered>
          <thead>
            <tr className="table-primary">
              <th className="text-center p-2 border">주문번호</th>
              <th className="text-center p-2 border">등록일</th>
              <th className="text-center p-2 border">요청메모</th>
              <th className="text-center p-2 border">이름</th>
              <th className="text-center p-2 border">배송메모</th>
              <th className="text-center p-2 border">주문상품</th>

              <th className="text-center p-2 border">무게</th>
              <th className="text-center p-2 border">배송료</th>
              <th className="text-center p-2 border">총액</th>
              <th className="text-center p-2 border">운송장번호</th>
              <th className="text-center p-2 border">쇼핑몰주문번호</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={11} className="text-center">
                주문 취소건이 없습니다.
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </NormalLayout>
  );
}

export default Cancel;
