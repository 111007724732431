import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";

import axios from "axios";
import sample from "../../assets/img/img_transfer_name.png";
import question from "../../assets/img/icon_question.png";
import Moment from "react-moment";
import Account from "../../assets/info/account.json";
import { addCommasToNumber } from "../../utils/AboutNumber";
import { getPoint } from "../../utils/PointRate";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

function DepositCharge({ isActive }) {
  const [balance, setBalance] = useState({
    deposit: 0.0,
    point: 0.0,
    credit: 0.0,
  });

  const [amount, setAmount] = useState(0);
  const [addPoint, setAddPoint] = useState(0);
  const [name, setName] = useState("");
  const [requestList, setRequestList] = useState(null);
  const [showSample, setShowSample] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getList = async () => {
    try {
      // 도매업체 정보 가져오기
      const resList = await axios.get(
        `${
          process.env.REACT_APP_WHOLESALE_API_URL
        }/deposit?wholesaler=${encodeURIComponent(
          cookies.get("info").profile
        )}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.msg === "no data") {
        setRequestList(null);
        return;
      }
      setRequestList(resList.data.data.list);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const getBalance = async () => {
    try {
      // 도매업체 정보 가져오기
      const resCompany = await axios.get(
        `${
          process.env.REACT_APP_WHOLESALE_API_URL
        }/dashboard/company?wholesaler=${encodeURIComponent(
          cookies.get("info").profile
        )}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      // Asset 정보 설정
      if (resCompany.data.result === "success") {
        cookies.set("balance", {
          deposit: resCompany.data.data.deposit,
          point: resCompany.data.data.point,
          credit: resCompany.data.data.credit,
        });

        setBalance({
          deposit: resCompany.data.data.deposit,
          point: resCompany.data.data.point,
          credit: resCompany.data.data.credit,
        });
      } else {
        console.error("데이터를 불러오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다.");
    }
  };

  useEffect(() => {
    if (isActive) {
      getBalance();
      getList();
    }
  }, [isActive]);

  useEffect(() => {
    let d = amount;
    let p = getPoint(d);

    setAddPoint(p);
  }, [amount]);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSave = async () => {
    if (name !== "") {
      setIsSubmitting(true);

      await axios
        .post(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/deposit`,
          {
            wholesaler: cookies.get("info").profile,
            amount,
            depositor: name,
            point: getPoint(amount),
          },
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        )
        .then((data) => {
          setModalOpen(false);
          setName("");
          getList();
          getBalance();
          setIsSubmitting(false);
        });
    } else {
      alert("입금자를 입력해 주세요");
    }
  };

  const delDepositItem = async (p) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(
      `입금 등록건을 삭제하시겠습니까?
(입금자:${p.depositor}, 입금예정액 $${p.amount})`
    );

    if (isConfirmed) {
      await axios
        .delete(
          `${
            process.env.REACT_APP_WHOLESALE_API_URL
          }/deposit?wholesaler=${encodeURIComponent(
            cookies.get("info").profile
          )}&depositIndex=${p.deposit_index}`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        )
        .then((data) => {
          setModalOpen(false);
          setName("");
          getList();
          getBalance();
          setIsSubmitting(false);
        });
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("사용자가 삭제를 취소했습니다.");
    }
  };

  return (
    <div className="text-dark">
      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          입금 신청 확인
        </ModalHeader>
        <ModalBody>
          입금자 {name} 이름으로 ${amount} 입금하시겠습니까?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalOpen(false)}>
            취소
          </Button>
          <Button color="primary" onClick={() => handleSave()}>
            저장
          </Button>
        </ModalFooter>
      </Modal>
      {requestList !== null &&
        requestList.map((p, idx) => {
          return (
            <div key={idx}>
              <div className="p-4 bg-danger rounded text-white mb-2">
                <Row>
                  <Col>
                    <li>
                      충전등록일 :{" "}
                      <Moment format="YYYY/MM/DD hh:mm">{p.created_at}</Moment>{" "}
                    </li>
                    <li>입금자명 : {p.depositor}</li>
                  </Col>
                  <Col>
                    <h4>
                      ${addCommasToNumber(p.amount)} 선불 충전금 입금 확인중
                    </h4>
                  </Col>
                  <Col className="text-center">
                    <Button
                      color="warning"
                      size="lg"
                      onClick={() => delDepositItem(p)}
                    >
                      취소
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          );
        })}
      <div
        className="text-center mt-3 p-5"
        style={{
          backgroundImage: "linear-gradient(to right, #67c9b0, #15c1bf)",
        }}
      >
        <h3 style={{ color: "white" }}>
          선불 충전금 :
          <span style={{ fontSize: "3rem" }}>
            ${addCommasToNumber(balance.deposit)}
          </span>
        </h3>
      </div>
      {balance.credit < 0 && (
        <Alert>
          {/* 정산완료 후 선불충전이 가능합니다.  */}
          크레딧 금액 :
          {balance.credit >= 0
            ? `$${balance.credit}`
            : `-$${Math.abs(balance.credit)}`}
        </Alert>
      )}

      <div className="p-3 mt-3">
        <div className="p-3">
          <Row>
            <Col className="border p-5">
              {" "}
              <h3 className="mb-3">입금 정보</h3>
              <Form>
                <Row>
                  <Col>
                    {" "}
                    <FormGroup>
                      <Label for="amount">입금할 금액(뉴질랜드 달러기준)</Label>

                      <Input
                        type="number"
                        name="amount"
                        step="1"
                        id="amount"
                        value={amount}
                        min={1000}
                        max={100000}
                        onChange={(e) => setAmount(e.target.value)}
                        required
                        placeholder="충전할 금액을 입력해 주세요. 충전가능액 1000 ~ 100,000"
                      />
                    </FormGroup>
                  </Col>
                  <Col className="text-start">
                    <br />
                    <br />
                    {addPoint > 0 && `포인트 적립 : ${addPoint}P`}
                  </Col>
                </Row>
                <div>
                  <Button
                    color="secondary"
                    outline
                    onClick={() => setAmount(1000)}
                  >
                    $1,000
                  </Button>{" "}
                  <Button
                    color="secondary"
                    outline
                    onClick={() => setAmount(5000)}
                  >
                    $5,000
                  </Button>{" "}
                  <Button
                    color="secondary"
                    outline
                    onClick={() => setAmount(10000)}
                  >
                    $10,000
                  </Button>
                </div>

                <FormGroup className="mt-4">
                  <Label for="name">입금자명(송금인의 영문이름) </Label>{" "}
                  <img
                    src={question}
                    width="20px"
                    alt="물음표 아이콘"
                    onClick={() => setShowSample(!showSample)}
                  />
                  {showSample === true && (
                    <div className="bg-light mb-2">
                      <img
                        src={sample}
                        width="100%"
                        alt="송금 영문명"
                        onClick={() => setShowSample(false)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  )}
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={name}
                    onChange={handleNameChange}
                    placeholder="송금인 영문이름을 입력해 주세요."
                    required
                  />
                </FormGroup>

                <Button
                  className="mt-3"
                  color="warning"
                  block
                  size="lg"
                  onClick={() => setModalOpen(true)}
                  disabled={amount >= 1000 && name !== "" ? false : true}
                >
                  선불 충전금 충전하기
                </Button>
                <p className="text-danger mt-2">
                  * 해외에서 송금 한 경우 입금확인까지 2~3일 소요됩니다.
                </p>
              </Form>
            </Col>
            <Col className="border p-5">
              <h3 className="mb-1">포인트 적립안내</h3>
              <p>선불 충전금 입금 금액에 따라 포인트가 추가 적립됩니다.</p>
              <li>$1000 ~ $4,999 : 포인트 5% 적립</li>
              <li> $5,000 ~ $9,999 : 포인트 10%적립</li>
              <li> $10,000 이상 : 포인트 15% 적립</li>

              <h5 className="mb-1 mt-5">* 카카오뱅크 송금 수수료 안내</h5>
              <li> 송금액 미화 5,000불 이하 : 5,000원 / 건당</li>
              <li> 송금액 미화 5,000불 초과 : 10,000원 / 건당</li>
              <li>
                <a
                  href="https://www.kakaobank.com/Help/Guide/Fee"
                  target="_blank"
                  rel="noreferrer"
                >
                  카카오뱅크 수수료 안내링크
                </a>
              </li>
            </Col>
          </Row>
        </div>
        <div className="mt-5">
          <h3>송금 계좌정보</h3>

          <div className="p-3 border">
            <Row>
              <Col>
                <li>은행명 : {Account.bank}</li>
                <li>계좌번호 : {Account.accountNo}</li>
                <li>Swift 코드/BIC : {Account.swift}</li>
                <li>수취인 : {Account.payee}</li>
                <li>수취인 주소 : {Account.address}</li>
                <a href="/info/transfer" target="_blank">
                  <p className="mt-3">* 카카오뱅크 뉴질랜드 송금 안내</p>
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepositCharge;
