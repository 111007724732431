import React from "react";
import { Row, Col, Card, CardBody, CardTitle, CardText } from "reactstrap";

function Introduce() {
  return (
    <>
      <h2 className="mb-4">메가몰 서비스 소개</h2>
      <div className="video-container mb-4">
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
          }}
        >
          <video
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            controls
            autoPlay
            muted
          >
            <source src="/videos/service-intro.mp4" type="video/mp4" />
            브라우저가 비디오를 지원하지 않습니다.
          </video>
        </div>
      </div>

      <Card className="mb-4 border-0 bg-light">
        <CardBody>
          <CardTitle tag="h4">서비스 특징</CardTitle>
          <Row className="mt-4">
            <Col md={6} className="mb-4">
              <h5 className="text-primary">
                <i className="fas fa-file-excel me-2"></i>
                간편한 주문 관리
              </h5>
              <p>
                엑셀 주문과 픽업 주문이 가능하며, 주문상태별 관리가 가능합니다.
              </p>
            </Col>
            <Col md={6} className="mb-4">
              <h5 className="text-primary">
                <i className="fas fa-wallet me-2"></i>
                다양한 결제 수단
              </h5>
              <p>
                선불충전금, 포인트, 크레딧 등 유연한 결제 옵션을 제공합니다.
              </p>
            </Col>
            <Col md={6} className="mb-4">
              <h5 className="text-primary">
                <i className="fas fa-search me-2"></i>
                실시간 재고 확인
              </h5>
              <p>실시간 재고 현황과 가격 정보를 언제든지 확인할 수 있습니다.</p>
            </Col>
            <Col md={6} className="mb-4">
              <h5 className="text-primary">
                <i className="fas fa-truck me-2"></i>
                주문/배송 트래킹
              </h5>
              <p>
                주문부터 배송까지 모든 과정을 실시간으로 확인할 수 있습니다.
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="border-0 bg-light">
        <CardBody>
          <CardTitle tag="h4">시작하기</CardTitle>
          <CardText>
            좌측 메뉴에서 원하시는 기능을 선택하시면 상세한 이용 방법을 확인하실
            수 있습니다.
          </CardText>
          <Row className="mt-4">
            <Col md={6}>
              <h5>처음 이용하시나요?</h5>
              <ol className="ps-3">
                <li>회원가입 및 로그인</li>
                <li>사업자 정보 등록</li>
                <li>주문 시작하기</li>
              </ol>
            </Col>
            <Col md={6}>
              <h5>고객 지원</h5>
              <p>
                문의사항이 있으시다면 언제든 연락주세요.
                <br />
                고객센터: *******
                <br />
                운영시간: 09:00 - 18:00 (주말/공휴일 제외)
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default Introduce;
