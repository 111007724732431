import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import axios from "axios";
import { Cookies } from "react-cookie";
import Moment from "react-moment";

const cookies = new Cookies();

function OrderForm({ order, setSelectedOrder }) {
  const [orderInfo, setOrderInfo] = useState({
    wholesaler: order.wholesaler,
    created_at: order.created_at,
    shipper_memo: order.shipper_memo,
    customer_name: order.customer_name,
    pcc: order.pcc,
    customer_tel: order.customer_tel,
    customer_mobile: order.customer_mobile,
    address: order.address,
    zip: order.zip,
    delivery_memo: order.delivery_memo,
    order_product: order.order_product,
    order_no: order.order_no,
  });
  // const [products, setProducts] = useState(order.order_product);

  // 주문 정보 변경 핸들러
  const handleOrderInfoInputChange = (event) => {
    const { name, value } = event.target;
    const newOrderInfo = { ...orderInfo };
    newOrderInfo[name] = value;
    setOrderInfo(newOrderInfo);
    setSelectedOrder(newOrderInfo);
  };

  // 제품 정보 변경 핸들러
  const handleInputChange = (index, event) => {
    const { name, value } = event.target;

    // 제품코드가 변경되는 경우 해당 업체에 맞는 가격정보 가져오기
    if(name==='')

    console.log(name, value);

    const newOrderInfo = { ...orderInfo };
    newOrderInfo.order_product[index][name] = value;
    setOrderInfo(newOrderInfo);
    setSelectedOrder(newOrderInfo);
  };

  // 새 제품 추가 핸들러
  const handleAddProduct = () => {
    const newProduct = {
      qauntity: 0,
      unitPrice: "0",
      productCode: "",
      productName: "",
    };
    const newOrderInfo = { ...orderInfo };

    newOrderInfo.order_product = [newProduct, ...orderInfo.order_product]; // 새로운 제품을 리스트의 맨 위에 추가
    setOrderInfo(newOrderInfo);
    setSelectedOrder(newOrderInfo);
  };
  // 제품 삭제 핸들러
  const handleRemoveProduct = (index) => {
    const newOrderProduct = orderInfo.order_product.filter(
      (_, i) => i !== index
    );

    const newOrderInfo = { ...orderInfo };
    newOrderInfo.order_product = newOrderProduct;
    setOrderInfo(newOrderInfo);
    setSelectedOrder(newOrderInfo);
  };

  return (
    <>
      <h5>주문개요</h5>
      <div className="m-2 border-bottom">
        <Row>
          <Col>
            <Form>
              <Row>
                <Col md={6}>- 업체코드 : {orderInfo.wholesaler}</Col>
                <Col md={6}>
                  - 등록일 :
                  <Moment format="YYYY-MM-DD HH:mm">
                    {orderInfo.created_at}
                  </Moment>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for="shipper_memo">- 요청메모</Label>
                    <Input
                      type="text"
                      name="shipper_memo"
                      value={orderInfo.shipper_memo}
                      id="shipper_memo"
                      onChange={(e) => handleOrderInfoInputChange(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      <h5>수령자 정보</h5>
      <div className="m-2 border-bottom">
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="customer_name">이름</Label>
              <Input
                type="text"
                name="customer_name"
                value={orderInfo.customer_name}
                id="customer_name"
                onChange={(e) => handleOrderInfoInputChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="pcc">통관부호</Label>
              <Input
                type="text"
                name="pcc"
                value={orderInfo.pcc}
                id="pcc"
                onChange={(e) => handleOrderInfoInputChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="customer_tel">전화번호</Label>
              <Input
                type="text"
                name="customer_tel"
                value={orderInfo.customer_tel}
                id="customer_tel"
                onChange={(e) => handleOrderInfoInputChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="customer_mobile">모바일번호</Label>
              <Input
                type="text"
                name="customer_mobile"
                value={orderInfo.customer_mobile}
                id="customer_mobile"
                onChange={(e) => handleOrderInfoInputChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="address">주소</Label>
              <Input
                type="text"
                name="address"
                value={orderInfo.address}
                id="address"
                onChange={(e) => handleOrderInfoInputChange(e)}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="zip">우편번호</Label>
              <Input
                type="text"
                name="zip"
                value={orderInfo.zip}
                id="zip"
                onChange={(e) => handleOrderInfoInputChange(e)}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="delivery_memo">배송메모</Label>
              <Input
                type="text"
                name="delivery_memo"
                value={orderInfo.delivery_memo}
                id="delivery_memo"
                onChange={(e) => handleOrderInfoInputChange(e)}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <h5>제품 정보</h5>

      <div className="m-2">
        <Row>
          <Col className="text-end">
            <Button
              color="success"
              onClick={handleAddProduct}
              className="mb-3"
              size="sm"
            >
              + 제품 추가
            </Button>{" "}
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Table bordered>
                      <thead>
                        <tr>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            제품코드
                          </th>
                          <th className="text-center">제품명</th>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            수량
                          </th>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            단가
                          </th>
                          <th
                            style={{ width: "130px" }}
                            className="text-center"
                          >
                            기능
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.order_product.map((product, index) => (
                          <tr key={index}>
                            <td>
                              <Input
                                type="text"
                                name="productCode"
                                value={product.productCode}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                required
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                name="productName"
                                value={product.productName}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                required
                              />
                            </td>
                            <td>
                              <Input
                                type="number"
                                name="qauntity"
                                min="1"
                                value={product.qauntity}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                required
                              />
                            </td>
                            <td>
                              <Input
                                type="number"
                                step="0.01"
                                name="unitPrice"
                                value={product.unitPrice}
                                onChange={(event) =>
                                  handleInputChange(index, event)
                                }
                                required
                              />
                            </td>
                            <td className="text-center">
                              {order.order_product.length > 1 && (
                                <Button
                                  size="sm"
                                  color="danger"
                                  onClick={() => handleRemoveProduct(index)}
                                >
                                  삭제
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default OrderForm;
