import React, { useState, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import axios from "axios";
import { Table, Button, Row, Col } from "reactstrap";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { Cookies } from "react-cookie";
import CompanyForm from "../../components/admin/CompanyForm";
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();

// 도매업체 관리
function AdminCompany() {
  const [data, setData] = useState(null);
  const [filteredList, setFilteredList] = useState();
  const [show, setShow] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수
  const [pageSize, setPageSize] = useState(10); // 페이지당 노출수 설정

  const navigate = useNavigate();

  useEffect(() => {
    getWholesaler();
  }, [currentPage]);

  const getWholesaler = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/company?page=${currentPage}&limit=${pageSize}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      if (resList.data.msg === "no auth") {
        navigate("/", { replace: true });
      }
      setData(resList.data.data.list);
      setFilteredList(resList.data.data.list);
      setCount(resList.data.data.count.count);
      setPageCount(Math.ceil(resList.data.data.count.count / pageSize));
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const handleAddCompany = async () => {
    setSelectedCompany(null);
    setShow(true);
  };

  const handleModify = (company) => {
    setSelectedCompany(company);
    setShow(true);
  };

  const handleDelete = async (id, name) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(`${name} 업체를 삭제하시겠습니까?`);

    if (isConfirmed) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/company/${id}`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );
        getWholesaler();
      } catch (err) {
        console.error(err);
      }
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("사용자가 삭제를 취소했습니다.");
    }
  };

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleShowDeliveryFee = (deliveryInfo) => {
    return (
      <>
        <li>뉴질랜드 : ${deliveryInfo["newzealand"]}</li>
        <li>호주 : ${deliveryInfo["australia"]}</li>
        <li>하이웰 : ${deliveryInfo["hiwell"]}</li>
        <li>비타존 : ${deliveryInfo["vitazone"]}</li>
      </>
    );
  };

  return (
    <NormalLayout>
      <div>
        <h3>도매업체 정보</h3>
      </div>
      {show && (
        <div className="p-5 border bg-light rounded">
          <CompanyForm
            fetchCompanies={getWholesaler}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            setShow={setShow}
          />
        </div>
      )}

      <div>
        <Button
          color="primary"
          onClick={() => handleAddCompany()}
          className="mb-3 mt-2"
        >
          + 도매업체 추가하기
        </Button>
      </div>
      {filteredList ? (
        <>
          <div className="border p-3">
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center">No</th>
                  <th className="text-center p-2 border">
                    거래처명
                    <br />
                    (사업자등록증 기준)
                  </th>
                  <th className="text-center p-2 border">
                    업체명
                    <br />
                    (주문서신청 기준)
                  </th>
                  <th className="text-center p-2 border">담당자</th>
                  <th className="text-center p-2 border">그룹</th>
                  <th className="text-center p-2 border">배송료 기준</th>
                  <th className="text-center p-2 border">신용구매</th>
                  <th className="text-center p-2 border">GST</th>
                  <th className="text-center p-2 border">메모</th>
                  <th className="text-center p-2 border">등록일</th>
                  <th className="text-center p-2 border">활성화</th>
                  <th className="text-center p-2 border">기능</th>
                </tr>
              </thead>
              <tbody>
                {filteredList.map((company, idx) => {
                  return (
                    <tr
                      key={idx}
                      className={
                        company.is_active === false ? "bg-warning" : ""
                      }
                    >
                      <td className="text-center">
                        {idx + 1 + currentPage * pageSize}
                      </td>
                      <td className="text-center">{company.company_name}</td>
                      <td className="text-center">{company.wholesaler}</td>
                      <td>
                        <li>{company.manager_name}</li>
                        <li>{company.manager_tel}</li>
                        <li>{company.manager_email}</li>
                      </td>

                      <td className="text-center">{company.purchase_grade}</td>
                      <td className="">
                        {handleShowDeliveryFee(company.delivery_fee)}
                      </td>
                      <td className="text-center">
                        {company.credit_purchase_enabled === false
                          ? "불가"
                          : "가능"}
                      </td>
                      <td className="text-center">
                        {company.gst === true ? "포함" : "미포함"}
                      </td>
                      <td className="text-center">{company.company_memo}</td>
                      <td className="text-center">
                        <Moment format="YYYY/MM/DD">
                          {company.created_at}
                        </Moment>
                      </td>
                      <td className="text-center">
                        {company.is_active === true ? "활성" : "비활성"}
                      </td>
                      <td className="text-center">
                        <button onClick={() => handleModify(company)}>
                          수정
                        </button>{" "}
                        <button
                          onClick={() =>
                            handleDelete(
                              company.company_index,
                              company.wholesaler
                            )
                          }
                        >
                          삭제
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReactPaginate
                previousLabel={"이전"}
                nextLabel={"다음"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"item active "}
                breakClassName={"item break-me "}
                pageClassName={"item pagination-page "}
                disabledClassName={"disabled-page"}
              />
            </div>
          </div>
        </>
      ) : (
        <>등록된 도매업체 정보가 없습니다.</>
      )}
    </NormalLayout>
  );
}

export default AdminCompany;
