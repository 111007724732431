import React, { useState } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import NormalLayout from "../../components/layout/NormalLayout";
import DepositCharge from "../../components/pay/DepositCharge";
import DepositHistory from "../../components/pay/DepositHistory";

function Deposit() {
  const [activeTab, setActiveTab] = useState("충전하기");

  return (
    <NormalLayout>
      <div>
        <h3>선불 충전금</h3>
      </div>
      <div className="p-3"></div>

      <Nav tabs className="mb-3">
        {["충전하기", "충전사용 내역"].map((tab) => (
          <NavItem key={tab}>
            <NavLink
              className={activeTab === tab ? "active" : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Container style={{ marginLeft: "0px" }}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="충전하기" className="border-0">
            <DepositCharge isActive={activeTab === "충전하기"} />
          </TabPane>
          <TabPane tabId="충전사용 내역" className="border-0">
            <DepositHistory isActive={activeTab === "충전사용 내역"} />
          </TabPane>
        </TabContent>
      </Container>
    </NormalLayout>
  );
}

export default Deposit;
