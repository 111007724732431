import React, { useState, useEffect } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import axios from "axios";
import { Table, Button, Row, Col } from "reactstrap";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import { Cookies } from "react-cookie";
import CompanyForm from "../../components/admin/CompanyForm";
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();

// 도매업체 관리
function AdminUser() {
  const [data, setData] = useState(null);
  const [filteredList, setFilteredList] = useState();
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수
  const [pageSize, setPageSize] = useState(10); // 페이지당 노출수 설정
  const [isEditing, setIsEditing] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getUsers();
  }, [currentPage]);

  const getUsers = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/user?page=${currentPage}&limit=${pageSize}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (resList.data.msg === "no auth") {
        navigate("/", { replace: true });
      }
      setData(resList.data.data.list);
      setFilteredList(resList.data.data.list);
      setCount(resList.data.data.count.count);
      setPageCount(Math.ceil(resList.data.data.count.count / pageSize));
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleStatusChange = async (email, is_active) => {
    const confirmed = window.confirm(
      `이 사용자(${email})의 상태를 '${
        is_active ? "활성" : "비활성"
      }'으로 변경하시겠습니까?`
    );
    if (confirmed) {
      await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/user`,
        {
          email,
          is_active,
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      alert("사용자 정보 업데이트 완료");
      getUsers();
    }
  };

  return (
    <NormalLayout>
      <div>
        <h3>사용자 정보</h3>
      </div>

      {filteredList ? (
        <>
          <div className="border p-3">
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center">No</th>
                  <th className="text-center">업체명</th>
                  <th className="text-center">사용자 이메일</th>
                  <th className="text-center">계정 활성 상태</th>
                </tr>
              </thead>
              <tbody>
                {filteredList.map((user, idx) => {
                  return (
                    <tr
                      key={idx}
                      className={user.is_active === false ? "bg-warning" : ""}
                    >
                      <td className="text-center">
                        {idx + 1 + currentPage * pageSize}
                      </td>
                      <td>{user.company_name}</td>

                      <td>{user.email}</td>
                      <td className="text-center">
                        {user.company_name === "Health Inside" ? (
                          <></>
                        ) : (
                          <>
                            <label>
                              <input
                                type="radio"
                                name={`status-${idx}`}
                                checked={user.is_active}
                                onChange={() =>
                                  handleStatusChange(user.email, true)
                                }
                              />
                              활성
                            </label>
                            <label style={{ marginLeft: "10px" }}>
                              <input
                                type="radio"
                                name={`status-${idx}`}
                                checked={!user.is_active}
                                onChange={() =>
                                  handleStatusChange(user.email, false)
                                }
                              />
                              비활성
                            </label>
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ReactPaginate
                previousLabel={"이전"}
                nextLabel={"다음"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"item active "}
                breakClassName={"item break-me "}
                pageClassName={"item pagination-page "}
                disabledClassName={"disabled-page"}
              />
            </div>
          </div>
        </>
      ) : (
        <>사용자가 없습니다.</>
      )}
    </NormalLayout>
  );
}

export default AdminUser;
