import React, { useState, useEffect, useRef, useContext } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import { Cookies } from "react-cookie";
import AdminDeposit from "../../components/admin/ledger/AdminDeposit";
import AdminPoint from "../../components/admin/ledger/AdminPoint";
import AdminInvoice from "../../components/admin/ledger/AdminInvoice";
import AdminCredit from "../../components/admin/ledger/AdminCredit";

const cookies = new Cookies();

// 도매업체들의 주문서 확인페이지
// 업체별 주문서 검토
function AdminLedger() {
  const [activeTab, setActiveTab] = useState("선불 충전금");

  return (
    <NormalLayout>
      <div>
        <h3>회계</h3>
      </div>
      <div className="mt-3"></div>
      <Nav tabs className="mb-3">
        {/* , "업체별 주문결제" */}
        {["선불 충전금", "포인트", "크레딧", "인보이스"].map((tab) => (
          <NavItem key={tab}>
            <NavLink
              className={activeTab === tab ? "active" : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Container style={{ marginLeft: "0px" }}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="선불 충전금" className="border-0">
            <AdminDeposit isActive={activeTab === "선불 충전금"} />
          </TabPane>
          <TabPane tabId="포인트" className="border-0">
            <AdminPoint isActive={activeTab === "포인트"} />
          </TabPane>
          <TabPane tabId="크레딧" className="border-0">
            <AdminCredit isActive={activeTab === "크레딧"} />
          </TabPane>
          <TabPane tabId="인보이스" className="border-0">
            <AdminInvoice isActive={activeTab === "인보이스"} />
          </TabPane>
          {/* <TabPane tabId="업체별 주문결제" className="border-0">
            업체별 주문결제
          </TabPane> */}
        </TabContent>
      </Container>
    </NormalLayout>
  );
}

export default AdminLedger;
