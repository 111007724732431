import React, { useEffect } from "react";
import NoSidebarLayout from "../components/layout/NoSidebarLayout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

function Pending() {
  const navigate = useNavigate();

  useEffect(() => {
    const checkStauts = async () => {
      try {
        // 엑셀 주문상태별 주문수
        const encodedWholesaler = encodeURIComponent(
          cookies.get("info").profile
        );

        // 도매업체 정보 가져오기
        const resCompany = await axios.get(
          `${process.env.REACT_APP_WHOLESALE_API_URL}/dashboard/company?wholesaler=${encodedWholesaler}`,
          {
            headers: {
              Authorization: cookies.get("info").token,
            },
          }
        );

        // 도매업체 정보가 없거나 활성화가 안 된 경우 다른 페이지로 이동
        // 도매업체 정보가 없는 경우 : 업체 입력 페이지로 이동
        // 활성화 상태가 아닌 경우 : 대기 페이지로 이동

        // if (resCompany.data.msg === "no data") {
        //   navigate("/company");
        //   return;
        // }

        // if (resCompany.data.data.is_active === true) {
        //   console.log("화사정보", resCompany.data.data);
        //   navigate("/");
        //   return;
        // }
        const companyInfo = cookies.get("info");
        companyInfo.t = resCompany.data.data.gst;
        companyInfo.c = resCompany.data.data.credit_purchase_enabled;
        companyInfo.a = resCompany.data.data.is_active;
        cookies.set("info", companyInfo);
      } catch (error) {
        console.error("데이터를 불러오는데 실패했습니다", error);
      }
    };

    checkStauts();
  }, []);
  return (
    <NoSidebarLayout>
      <div style={styles.container}>
        <h1 style={styles.heading}>계정 승인 대기 중입니다</h1>
        <p style={styles.message}>
          회사 정보 등록이 완료되었습니다. 현재 운영자가 계정 승인을 검토
          중입니다.
        </p>
        <p style={styles.message}>
          계정이 승인되면 이메일로 알려드리겠습니다. 궁금한 사항이 있으시면 고객
          지원팀(info@megamall.nz)에 문의해 주세요.
        </p>
        <p style={styles.message}>
          계정 승인이 되면 로그아웃 후 다시 인증해 주시기 바랍니다. 감사합니다.
        </p>
        <div style={styles.spinner}></div>
      </div>
    </NoSidebarLayout>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
  },
  heading: {
    fontSize: "2rem",
    marginBottom: "20px",
    color: "#333",
  },
  message: {
    fontSize: "1.2rem",
    marginBottom: "15px",
    color: "#555",
  },
  spinner: {
    marginTop: "20px",
    width: "50px",
    height: "50px",
    border: "5px solid rgba(0, 0, 0, 0.1)",
    borderTop: "5px solid #333",
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

export default Pending;
