export const genOrderListTotalAmount = (arrOrderList, objProduct) => {
  let tempTotalAmount = 0;

  console.log("데이터 확인", arrOrderList, objProduct);
  // 주문리스트에서 제품 리스트 출력 후 가격 업데이트(수량*가격)

  // 주문정보가 있는 경우에만 계산
  if (arrOrderList.length === 0) {
    return tempTotalAmount;
  } else {
    arrOrderList.map((order) => {
      order.items.map((item) => {
        // 만약 product 가격정보에 매칭되는게 있다면 수량*가격 후 tempTotalAmount에 더하기
        if (objProduct.hasOwnProperty(item.productCode)) {
          tempTotalAmount +=
            item.qauntity * parseFloat(objProduct[item.productCode].price);
        }
      });
    });

    return tempTotalAmount;
  }
};
