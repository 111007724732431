import React from "react";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";

function PaymentPrepaid() {
  return (
    <>
      <h3>선불충전금</h3>
      <div className="video-container mb-4">
        <div
          style={{
            position: "relative",
            paddingBottom: "56.25%",
            height: 0,
          }}
        >
          <video
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            controls
          >
            <source src="/videos/excel-order-guide.mp4" type="video/mp4" />
            브라우저가 비디오를 지원하지 않습니다.
          </video>
        </div>
      </div>
      <Card className="mb-4 border-0 bg-light">
        <CardBody>
          <CardTitle tag="h5">픽업 주문 절차</CardTitle>
          <ol>
            <li>픽업 템플릿 다운로드</li>
            <li>상품코드, 수량 입력</li>
            <li>픽업 파일 업로드</li>
            <li>주문 내역 확인</li>
            <li>결제 진행</li>
          </ol>
          <CardText>
            <strong>TIP:</strong> 자주 주문하는 상품은 픽업 템플릿을 저장해두면
            편리합니다.
          </CardText>
        </CardBody>
      </Card>
    </>
  );
}

export default PaymentPrepaid;
