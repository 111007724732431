import React, { useState, useEffect, useContext } from "react";
import NormalLayout from "../../components/layout/NormalLayout";
import { genExcelDownloadFunc } from "../../utils/GenExcelDownloadFile";
import axios from "axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
  Row,
  Col,
} from "reactstrap";
import Moment from "react-moment";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import AddExcelOrderForm from "../../components/admin/order/AddExcelOrderForm";

const cookies = new Cookies();

// 도매업체들의 주문서 확인페이지
// 업체별 주문서 검토
function AdminRegistered() {
  const [data, setData] = useState(null);
  const [ordersObj, setOrdersObj] = useState({});
  const [wholesalerArr, setWholesalerArr] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState();
  const [currentWholesaler, setCurrentWholesaler] = useState();

  const [addOrderInfo, setAddOrderInfo] = useState({});
  const [showAddOrderForm, setShowAddOrderForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const showAddOrderFormToggle = () => setShowAddOrderForm(!showAddOrderForm);

  useEffect(() => {
    excelListData();
  }, []);

  const excelListData = async () => {
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/order?status=REGISTERED`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (resList.data.msg !== "no data") {
        setData(resList.data.data.list);
        setFilteredOrders(resList.data.data.list);
        genSummary(resList.data.data.list);
      } else {
        setData(null);
        setFilteredOrders();
        // genSummary(resList.data.data.list);
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const genSummary = (orders) => {
    let tempObj = {};

    orders.map((order) => {
      if (tempObj.hasOwnProperty(order.wholesaler)) {
        tempObj[order.wholesaler].push(order);
      } else {
        tempObj[order.wholesaler] = [order];
      }
    });

    const sortedKeys = Object.keys(tempObj).sort();

    // 도매업체 리스트 및 업체별 주문건 설정
    setWholesalerArr(sortedKeys);
    setOrdersObj(tempObj);
  };

  // 주문건별 총 상품금액 계산함수
  const genAmount = (items) => {
    let amount = 0;

    items.map((item) => {
      let price = convertPriceFormat(
        item.productCode,
        item.unitPrice,
        item.qauntity
      ).replace("$", "");
      if (!isNaN(price)) {
        amount += parseFloat(price);
      }
    });

    return amount.toFixed(2);
  };

  const convertPriceFormat = (productCode, unitPrice, qauntity) => {
    let resValue = null;
    let pcode = productCode.toUpperCase();

    // 예외처리 코드 : BRING, CHEMIST, MART, PROMO, SPECIAL, 공백
    if (pcode === "BRING" || pcode === "PROMO") {
      resValue = "$0";
    } else if (
      pcode === "CHEMIST" ||
      pcode === "MART" ||
      pcode === "SPECIAL" ||
      pcode === ""
    ) {
      resValue = "미정";
    } else {
      resValue =
        "$" +
        parseFloat(unitPrice * qauntity)
          .toFixed(2)
          .toString();
    }
    return resValue;
  };

  const selectedOrders = (wholesaler) => {
    setFilteredOrders(ordersObj[wholesaler]);
    setCurrentWholesaler(wholesaler);
  };

  const getExcelDownload = async () => {
    let tempOrders = data;
    let ordersNoArr = [];

    tempOrders.map((o) => {
      ordersNoArr.push(o.order_index);
    });

    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/order/status`,
        {
          ordersNoArr,
          after: "PROCESSING",
          before: "REGISTERED",
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (resList.data.result === "success") {
        excelListData();
        tempOrders.sort((a, b) => {
          if (a.wholesaler < b.wholesaler) {
            return -1;
          }
          if (a.wholesaler > b.wholesaler) {
            return 1;
          }
          return 0;
        });

        // 주문건 상태변경 : 주문등록 완료 > 주문 처리 중

        genExcelDownloadFunc(tempOrders);
        navigate("/admin/processing");
      } else {
        console.error("주문건 상태를 업데이트하는데 실패했습니다");
      }
    } catch (error) {
      console.error("주문건 상태를 업데이트하는데 실패했습니다", error);
    }
  };

  const deleteOrder = async (orderIndex) => {
    try {
      const resList = await axios.delete(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/order?orderindex=${orderIndex}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      if (resList.data.result === "success") {
        excelListData();
      } else {
        console.error("주문건을 삭제하는데 실패했습니다");
      }
    } catch (error) {
      console.error("주문건을 삭제하는데 실패했습니다", error);
    }
  };

  const handleDelete = (orderIndex, name) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(`${name}님 주문을 삭제하시겠습니까?`);

    if (isConfirmed) {
      deleteOrder(orderIndex);
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("사용자가 삭제를 취소했습니다.");
    }
  };

  const handleDownload = () => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(`"주문준비중" 상태로 변경하시겠습니까?`);

    if (isConfirmed) {
      getExcelDownload();
    } else {
      // 사용자가 '취소'를 클릭했을 때 수행할 작업
      console.log("취소");
    }
  };

  const handleReset = () => {
    showAddOrderFormToggle();
    excelListData();
  };

  return (
    <NormalLayout>
      <Modal
        isOpen={showAddOrderForm}
        toggle={showAddOrderFormToggle}
        style={{ maxWidth: "80%" }}
      >
        <ModalHeader toggle={showAddOrderFormToggle}>
          업체 엑셀주문 등록하기
        </ModalHeader>
        <ModalBody>
          <AddExcelOrderForm handleReset={handleReset} />
        </ModalBody>
        <ModalFooter>
          {/* <Button
            color="primary"
            onClick={() => alert("저장")}
            disabled={
              addOrderInfo.products && addOrderInfo.products.length === 0
                ? true
                : false
            }
          >
            {isSubmitting ? "처리 중..." : "주문등록"}
          </Button> */}
          <Button color="secondary" onClick={showAddOrderFormToggle}>
            취소
          </Button>
        </ModalFooter>
      </Modal>
      <div>
        <h3>
          주문등록 완료{" "}
          {data && data.length !== 0 ? <>({data.length}건)</> : <></>}
        </h3>
      </div>
      <div className="text-end mb-2">
        <Button color="success" onClick={() => setShowAddOrderForm(true)}>
          업체 엑셀주문 등록
        </Button>
      </div>
      {filteredOrders ? (
        <>
          <div className="border p-3">
            <div className="mb-3">
              <div className="p-5  mb-3 border bg-light">
                <h4>업체별 주문현황</h4>
                <Row className="mt-3">
                  {wholesalerArr &&
                    wholesalerArr.length > 0 &&
                    wholesalerArr.map((wholesaler, index) => {
                      return (
                        <Col
                          key={index}
                          md={2}
                          className="border p-2 rounded m-2 text-center bg-white"
                          style={{
                            backgroundColor:
                              currentWholesaler === wholesaler ? "orange" : "",
                          }}
                        >
                          <div onClick={() => selectedOrders(wholesaler)}>
                            <h6 className="bold_600">{wholesaler}</h6>
                            {ordersObj && ordersObj[wholesaler].length}건
                          </div>
                        </Col>
                      );
                    })}
                </Row>
                <hr />
                <Row className="mt-5">
                  <Col className="text-center">
                    {data && data.length > 0 && (
                      <Button
                        size="lg"
                        color="warning"
                        onClick={() => handleDownload()}
                      >
                        엑셀 다운로드
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>{" "}
            </div>
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center p-2 border">등록일시</th>
                  <th className="text-center p-2 border">업체명</th>
                  <th className="text-center p-2 border">요청메모</th>
                  <th className="text-center p-2 border">이름</th>

                  {/* <th className="text-center p-2 border">전화번호</th>
                  <th className="text-center p-2 border">모바일번호</th>
                  <th className="text-center p-2 border">통관부호</th>
                  <th className="text-center p-2 border">우편번호</th>
                  <th className="text-center p-2 border">주소</th> */}
                  <th className="text-center p-2 border">배송메모</th>
                  <th className="text-center p-2 border">주문상품</th>
                  <th className="text-center p-2 border">
                    예상금액
                    <br />
                    (배송비 제외)
                  </th>

                  <th className="text-center p-2 border">기능</th>
                </tr>
              </thead>
              <tbody>
                {filteredOrders.map((o, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-center">
                        <Moment format="YYYY/MM/DD HH:mm">
                          {o.created_at}
                        </Moment>
                      </td>
                      <td className="text-center">{o.wholesaler}</td>
                      <td className="text-center cell">{o.shipper_memo}</td>
                      <td className="text-center">{o.customer_name}</td>
                      {/* <td className="text-center">{o.customer_tel}</td>
                      <td className="text-center">{o.customer_mobile}</td>
                      <td className="text-center">{o.pcc}</td>

                      <td className="text-center">{o.zip}</td>
                      <td>{o.address}</td> */}

                      <td>{o.delivery_memo}</td>
                      <td style={{ padding: "0px" }}>
                        <Table
                          bordered
                          style={{ margin: "0px", width: "100%" }}
                        >
                          <tbody>
                            {o.order_product.map((i, index) => {
                              return (
                                <tr key={index}>
                                  <td className="cell70">{i.productCode}</td>

                                  <td className="cell">{i.productName}</td>
                                  <td
                                    style={{ width: "60px" }}
                                    className="text-center"
                                  >
                                    {i.qauntity}개
                                  </td>
                                  <td
                                    style={{ width: "70px" }}
                                    className="text-center"
                                  >
                                    {convertPriceFormat(
                                      i.productCode,
                                      i.unitPrice,
                                      i.qauntity
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </td>
                      <td className="text-center">
                        ${genAmount(o.order_product)}
                      </td>

                      <td className="text-center" style={{ width: "120px" }}>
                        <Button
                          color="danger"
                          style={{ fontSize: "12px" }}
                          onClick={() =>
                            handleDelete(o.order_index, o.customer_name)
                          }
                        >
                          삭제
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <>주문 데이터 없음</>
      )}
    </NormalLayout>
  );
}

export default AdminRegistered;
