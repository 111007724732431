import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Form,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { addCommasToNumber } from "../../../utils/AboutNumber";
import ReactPaginate from "react-paginate";

import axios from "axios";
import { Cookies } from "react-cookie";

import ExcelOrderList from "../list/ExcelOrderList";
import PickupOrderList from "../list/PickupOrderList";
import { genExcelForXero } from "../../../utils/GenExcelForXero";

const cookies = new Cookies();

function InvoiceUnpaid({ isActive }) {
  const [detailExcelOrder, setDetailExcelOrder] = useState([]);
  const [detailPickupOrder, setDetailPickupOrder] = useState([]);
  const [wholesaler, setWholesaler] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState({
    wholesaler: "",
    year: "",
    month: "",
    credit_payment: 0,
    invoice_memo: "",
  });

  const [showPaidCheckingForm, setShowPaidCheckingForm] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수
  const [pointPayableProductCodes, setPointPayableProductCodes] = useState([]);

  const showPaidCheckingFormToggle = () =>
    setShowPaidCheckingForm(!showPaidCheckingForm);

  useEffect(() => {
    if (isActive) {
      getData();
      setDetailExcelOrder([]);
      setDetailPickupOrder([]);
      setDetailExcelOrder([]);
      setDetailPickupOrder([]);
    }
  }, [isActive]);

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const getData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/invoice/unpaid?page=${currentPage}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      setInvoices(res.data.data.list);
      setCount(res.data.data.count.count);
      setPageCount(Math.ceil(res.data.data.count.count / 10));

      // 포인트 결제 가능한 제품 코드 가져오기
      const resPayablePointProductCodes = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/search/product/point`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      setPointPayableProductCodes(resPayablePointProductCodes.data);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const handleGetInvoiceDetail = async (
    selectedYear,
    selectedMonth,
    wholesaler
  ) => {
    setWholesaler(wholesaler);
    console.log("업체 ", wholesaler);
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/invoice/${selectedYear}/${selectedMonth}/${wholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      const res = resList.data;
      console.log("확인", res.data);
      if (res.result === "success") {
        setDetailExcelOrder(res.data.excelOrder);
        setDetailPickupOrder(res.data.pickupOrder);
      } else {
        // 상세 내역이 없을 때
        setDetailExcelOrder([]);
        setDetailPickupOrder([]);
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const handleGetXeroForm = async (selectedYear, selectedMonth, wholesaler) => {
    setWholesaler(wholesaler);
    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/invoice/${selectedYear}/${selectedMonth}/${wholesaler}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      const res = resList.data;
      console.log("확인", res.data);
      if (res.result === "success") {
        const gst = res.data.gst;

        // xero 폼으로 가공하기
        const orderList = {
          excel: res.data.excelOrder,
          pickup: res.data.pickupOrder,
        };

        genExcelForXero(
          orderList,
          wholesaler,
          gst,
          selectedYear,
          selectedMonth,
          pointPayableProductCodes
        );

        setDetailExcelOrder(res.data.excelOrder);
        setDetailPickupOrder(res.data.pickupOrder);

        // 날짜별로 인보이스
      } else {
        // 상세 내역이 없을 때
        alert("정산할 데이터가 없습니다.");
        setDetailExcelOrder([]);
        setDetailPickupOrder([]);
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  // 테이블 내에 정산 버튼 클릭시 모달창 띄우기
  const handleShowPaidCheck = async (invoice) => {
    // setSelectedOrder(o);
    console.log(invoice);
    setSelectedInvoice(invoice);
    setShowPaidCheckingForm(true);
  };

  // 메모 정보 변경 핸들러
  const handleChangeMemo = (event) => {
    setSelectedInvoice({
      ...selectedInvoice,
      invoice_memo: event.target.value,
    });
  };

  // 정산 확인처리
  const handlePaidInvoice = (invoice) => {
    // 사용자에게 확인을 요청하는 알림 표시
    const isConfirmed = window.confirm(
      `${invoice.wholesaler} ${invoice.year}년 ${invoice.month}월 인보이스 정산건을 정산완료로 변경하시겠습니까?`
    );

    if (isConfirmed) {
      paidInvoice(invoice);
    } else {
      console.log("상태변경을 취소했습니다.");
    }
  };

  // 픽업확인 및 결제처리 하기
  const paidInvoice = async (invoice) => {
    try {
      const resList = await axios.put(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/invoice`,
        {
          invoice,
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      console.log(resList.data);

      if (resList.data.result === "success") {
        alert(
          `${invoice.wholesaler} ${invoice.year}년 ${invoice.month}월 인보이스 정산확인 완료`
        );
        showPaidCheckingFormToggle();
        getData();
      } else {
        console.error("상태 변경 실패");
      }
    } catch (error) {
      console.error("상태 변경 실패", error);
    }
  };

  return (
    <div>
      {invoices.length > 0 ? (
        <>
          <Modal
            isOpen={showPaidCheckingForm}
            toggle={showPaidCheckingFormToggle}
            className="custom-modal"
          >
            {/* 정산확인 모달창 */}
            <ModalHeader toggle={showPaidCheckingFormToggle}>
              정산확인
            </ModalHeader>
            <ModalBody>
              <div className="m-2">
                <h5>정산대상</h5>
              </div>
              <div className="m-2 border-bottom">
                {selectedInvoice && (
                  <Row>
                    <Col>
                      <Form>
                        <Row>
                          <Col md={6}>
                            <li>업체명 : {selectedInvoice.wholesaler}</li>
                            <li>
                              인보이스 대상년월 : {selectedInvoice.year}년{" "}
                              {selectedInvoice.month}월
                            </li>
                          </Col>
                          <Col md={6}>
                            <li>
                              정산대상 금액 : $
                              {parseFloat(
                                selectedInvoice.credit_payment
                              ).toFixed(2)}
                            </li>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label for="memo">- 메모</Label>
                              <Input
                                type="textarea"
                                name="memo"
                                value={selectedInvoice.invoice_memo ?? ""}
                                id="memo"
                                onChange={(e) => handleChangeMemo(e)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => handlePaidInvoice(selectedInvoice)}
              >
                정산확인
              </Button>
              <Button color="secondary" onClick={showPaidCheckingFormToggle}>
                취소
              </Button>
            </ModalFooter>
          </Modal>
          <Table hover bordered className="mt-3" key={"table"}>
            <thead>
              <tr className="table-primary">
                <th className="text-center" key={"company"}>
                  업체명
                </th>
                <th className="text-center">년도</th>
                <th className="text-center">월</th>
                <th className="text-center">제품주문 가격</th>
                <th className="text-center">배송비</th>
                <th className="text-center">GST(15%)</th>
                <th className="text-center">총 정산금액</th>
                <th className="text-center">기능</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice, idx) => {
                return (
                  <tr key={idx} className="text-center">
                    <th scope="row">{invoice.wholesaler}</th>
                    <td>{invoice.year}</td>
                    <td>{invoice.month}</td>
                    <td>
                      $
                      {addCommasToNumber(
                        parseFloat(
                          (
                            parseFloat(invoice.credit_payment) +
                            parseFloat(invoice.deposit_payment) +
                            parseFloat(invoice.point_payment) -
                            parseFloat(invoice.delivery_fee_amount)
                          ).toFixed(2)
                        )
                      )}
                    </td>
                    <td>${invoice.delivery_fee_amount}</td>
                    <td>
                      $
                      {(
                        parseFloat(invoice.credit_payment) +
                        parseFloat(invoice.deposit_payment) +
                        parseFloat(invoice.point_payment) -
                        parseFloat(invoice.delivery_fee_amount) -
                        (parseFloat(invoice.credit_payment) +
                          parseFloat(invoice.deposit_payment) +
                          parseFloat(invoice.point_payment) -
                          parseFloat(invoice.delivery_fee_amount)) /
                          1.15
                      ).toFixed(2)}
                    </td>
                    <td>${parseFloat(invoice.credit_payment).toFixed(2)}</td>
                    <td>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() => handleShowPaidCheck(invoice)}
                      >
                        정산
                      </Button>{" "}
                      <Button
                        size="sm"
                        color="primary"
                        onClick={() =>
                          handleGetInvoiceDetail(
                            invoice.year,
                            invoice.month,
                            invoice.wholesaler
                          )
                        }
                      >
                        상세보기
                      </Button>{" "}
                      <Button
                        size="sm"
                        color="info"
                        onClick={() =>
                          handleGetXeroForm(
                            invoice.year,
                            invoice.month,
                            invoice.wholesaler
                          )
                        }
                      >
                        Xero
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ReactPaginate
              previousLabel={"이전"}
              nextLabel={"다음"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"item active "}
              breakClassName={"item break-me "}
              pageClassName={"item pagination-page "}
              disabledClassName={"disabled-page"}
            />
          </div>
        </>
      ) : (
        <div className="border p-5 text-center">정산확인 대상 없음</div>
      )}
      {(detailExcelOrder.length > 0 || detailPickupOrder.length > 0) && (
        <>
          <ExcelOrderList
            wholesaler={wholesaler}
            detailExcelOrder={detailExcelOrder}
          />

          <PickupOrderList
            wholesaler={wholesaler}
            detailPickupOrder={detailPickupOrder}
          />
        </>
      )}
    </div>
  );
}

export default InvoiceUnpaid;
