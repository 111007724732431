import * as XLSX from "xlsx";

export const genExcelForXero = (
  orderList,
  wholesaler,
  gst,
  year,
  month,
  pointPayableProductCodes
) => {
  const codes = pointPayableProductCodes[0].codes;
  const invoceTitle = `${wholesaler}-${year}-${month}`;

  // 주요 상수값 선언
  const accountCode = "201";

  // console.log(
  //   orderList,
  //   wholesaler,
  //   gst,
  //   year,
  //   month,
  //   pointPayableProductCodes
  // );

  let orderArr = [];

  // 엑셀 주문 처리
  if (orderList.excel.length > 0) {
    let totalPoint = 0.0;
    let totalAmount = 0.0;
    let totalDeliveryFee = 0.0;

    orderList.excel.map((order, idx) => {
      // transactions에 type이 point가 있는 경우 포인트 추가
      let point = 0;
      if (order.transactions.some((t) => t.type === "point")) {
        point = Math.abs(
          order.transactions.find((t) => t.type === "point").amount
        );
      }

      totalPoint += point;

      // 배달료가 있는 경우 배달료 추가
      // parseFloat(order.delivery_fee) > 0 &&
      //   console.log("배달료", order.delivery_fee);
      totalDeliveryFee += parseFloat(order.delivery_fee);

      // 제품구입비 추가
      order.order_product.map((item, idx2) => {
        totalAmount += parseFloat(
          (parseInt(item.qauntity) * parseFloat(item.unitPrice)).toFixed(2)
        );
      });
    });
    // 제품구입비
    orderArr.push([
      wholesaler,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      invoceTitle,
      null,
      null,
      null,
      null,
      "product - overseas",
      1,
      parseFloat(totalAmount - totalPoint).toFixed(2),
      null,
      accountCode,
      gst ? "15% GST on Income" : "Zero Rated",
      null,
      null,
      null,
      null,
      null,
      null,
    ]);

    // 배송료
    orderArr.push([
      wholesaler,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      invoceTitle,
      null,
      null,
      null,
      null,
      "delivery fee - overseas",
      1,
      parseFloat(totalDeliveryFee).toFixed(2),
      null,
      accountCode,
      "Zero Rated",
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
  }

  // 픽업 주문 처리
  if (orderList.pickup.length > 0) {
    let totalPoint = 0.0;
    let totalAmount = 0.0;

    orderList.pickup.map((order, idx) => {
      // transactions에 type이 point인 것이 있는지 확인
      let point = 0;
      if (order.transactions.some((t) => t.type === "point")) {
        point = Math.abs(
          order.transactions.find((t) => t.type === "point").amount
        );
      }

      totalPoint += point;

      // 포인트 잔액 판단 및 내역 기록
      order.order_product.map((item, idx2) => {
        totalAmount += parseFloat(
          (parseInt(item.qauntity) * parseFloat(item.unitPrice)).toFixed(2)
        );
      });
    });

    orderArr.push([
      wholesaler,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      invoceTitle,
      null,
      null,
      null,
      null,
      "product - local",
      1,
      parseFloat(totalAmount - totalPoint).toFixed(2),
      null,
      accountCode,
      "15% GST on Income",
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
  }

  const ws = XLSX.utils.aoa_to_sheet([
    [
      "*ContactName",
      "EmailAddress",
      "POAddressLine1",
      "POAddressLine2",
      "POAddressLine3",
      "POAddressLine4",
      "POCity",
      "PORegion",
      "POPostalCode",
      "POCountry",
      "*InvoiceNumber",
      "Reference",
      "*InvoiceDate",
      "*DueDate",
      "InventoryItemCode",
      "*Description",
      "*Quantity",
      "*UnitAmount",
      "Discount",
      "*AccountCode",
      "*TaxType",
      "TrackingName1",
      "TrackingOption1",
      "TrackingName2",
      "TrackingOption2",
      "Currency",
      "BrandingTheme",
    ],
    ...orderArr.map((order) => order), // 두 번째 행부터 데이터
  ]);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "uploadform");

  // 파일 생성 및 내보내기
  XLSX.writeFile(wb, `xero_${wholesaler}_${year}_${month}.xlsx`);
};

// export const genExcelForXero = (
//   orderList,
//   wholesaler,
//   gst,
//   year,
//   month,
//   pointPayableProductCodes
// ) => {
//   const codes = pointPayableProductCodes[0].codes;

//   let orderArr = [];

//   // 엑셀 주문 처리
//   if (orderList.excel.length > 0) {
//     orderList.excel.map((order, idx) => {
//       // transactions에 type이 point인 것이 있는지 확인
//       let point = 0;
//       if (order.transactions.some((t) => t.type === "point")) {
//         point = Math.abs(
//           order.transactions.find((t) => t.type === "point").amount
//         );
//       }

//       parseFloat(order.delievery_fee) > 0 &&
//         console.log("배달료", order.delivery_fee);
//       orderArr.push([
//         wholesaler,
//         null,
//         null,
//         null,
//         null,
//         null,
//         null,
//         null,
//         null,
//         null,
//         `inv-excel-${year}-${month}-${idx + 1}`,
//         null,
//         `${order.delivery_at.slice(0, 10)}`,
//         null,
//         null,
//         "delivery_fee",
//         1,
//         order.delivery_fee,
//         null,
//         null,
//         "zero rate",
//         null,
//         null,
//         null,
//         null,
//         null,
//         null,
//       ]);

//       order.order_product.map((item, idx2) => {
//         let referenceMemo = null;
//         let discount = null;

//         console.log(item);
//         // 포인트 결제 내역이 있는가?
//         if (point > 0) {
//           const isIncluded = codes.includes(item.productCode);
//           // 포인트로 구입 가능한 제품인 경우 포인트 만큼 디스카운스 적용
//           if (isIncluded) {
//             let productPrice = parseFloat(
//               (parseInt(item.qauntity) * parseFloat(item.unitPrice)).toFixed(2)
//             );
//             if (point === productPrice) {
//               discount = point;
//               point = 0;
//               referenceMemo = "포인트 전액 사용";
//             } else if (point > productPrice) {
//               discount = productPrice;
//               point -= productPrice;
//               referenceMemo = `포인트 일부 사용(${productPrice})`;
//             } else {
//               discount = point;
//               point = 0;
//               referenceMemo = `포인트 전액 사용(${point})`;
//             }
//           }
//         }
//         orderArr.push([
//           wholesaler,
//           null,
//           null,
//           null,
//           null,
//           null,
//           null,
//           null,
//           null,
//           null,
//           `inv-excel-${year}-${month}-${idx + 1}`,
//           referenceMemo,
//           `${order.delivery_at.slice(0, 10)}`,
//           null,
//           null,
//           item.productName,
//           item.qauntity,
//           (parseInt(item.qauntity) * parseFloat(item.unitPrice)).toFixed(2),
//           discount,
//           null,
//           gst ? "gst 15%" : "zero rate",
//           null,
//           null,
//           null,
//           null,
//           null,
//           null,
//         ]);
//       });
//     });
//   }

//   // 픽업 주문 처리
//   if (orderList.pickup.length > 0) {
//     orderList.pickup.map((order, idx) => {
//       // transactions에 type이 point인 것이 있는지 확인
//       let point = 0;
//       if (order.transactions.some((t) => t.type === "point")) {
//         point = Math.abs(
//           order.transactions.find((t) => t.type === "point").amount
//         );
//       }

//       // 포인트 잔액 판단 및 내역 기록
//       order.order_product.map((item, idx2) => {
//         let referenceMemo = null;
//         let discount = null;

//         console.log(item);
//         // 포인트 결제 내역이 있는가?
//         if (point > 0) {
//           const isIncluded = codes.includes(item.productCode);
//           // 포인트로 구입 가능한 제품인 경우 포인트 만큼 디스카운스 적용
//           if (isIncluded) {
//             let productPrice = parseFloat(
//               (parseInt(item.qauntity) * parseFloat(item.unitPrice)).toFixed(2)
//             );
//             if (point === productPrice) {
//               discount = point;
//               point = 0;
//               referenceMemo = "포인트 전액 사용";
//             } else if (point > productPrice) {
//               discount = productPrice;
//               point -= productPrice;
//               referenceMemo = `포인트 일부 사용(${productPrice})`;
//             } else {
//               discount = point;
//               point = 0;
//               referenceMemo = `포인트 전액 사용(${point})`;
//             }
//           }
//         }

//         orderArr.push([
//           wholesaler,
//           null,
//           null,
//           null,
//           null,
//           null,
//           null,
//           null,
//           null,
//           null,
//           `inv-pickup-${year}-${month}-${idx + 1}`,
//           referenceMemo,
//           `${order.pickup_at.slice(0, 10)}`,
//           null,
//           null,
//           item.productName,
//           item.qauntity,
//           (parseInt(item.qauntity) * parseFloat(item.unitPrice)).toFixed(2),
//           discount,
//           null,
//           "gst 15%",
//           null,
//           null,
//           null,
//           null,
//           null,
//           null,
//         ]);
//       });
//     });
//   }

//   const ws = XLSX.utils.aoa_to_sheet([
//     [
//       "*ContactName",
//       "EmailAddress",
//       "POAddressLine1",
//       "POAddressLine2",
//       "POAddressLine3",
//       "POAddressLine4",
//       "POCity",
//       "PORegion",
//       "POPostalCode",
//       "POCountry",
//       "*InvoiceNumber",
//       "Reference",
//       "*InvoiceDate",
//       "*DueDate",
//       "InventoryItemCode",
//       "*Description",
//       "*Quantity",
//       "*UnitAmount",
//       "Discount",
//       "*AccountCode",
//       "*TaxType",
//       "TrackingName1",
//       "TrackingOption1",
//       "TrackingName2",
//       "TrackingOption2",
//       "Currency",
//       "BrandingTheme",
//     ],
//     ...orderArr.map((order) => order), // 두 번째 행부터 데이터
//   ]);

//   const wb = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(wb, ws, "uploadform");

//   // 파일 생성 및 내보내기
//   XLSX.writeFile(wb, `xero_${wholesaler}_${year}_${month}.xlsx`);
// };
