// ManualPage.js
import React from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Badge,
} from "reactstrap";
import Introduce from "../../components/manual/Introduce";
import OrderExcel from "../../components/manual/OrderExcel";
import OrderPickup from "../../components/manual/OrderPickup";
import PaymentPrepaid from "../../components/manual/PaymentPrepaid";
import PaymentPoint from "../../components/manual/PaymentPoint";
import PaymentCredit from "../../components/manual/PaymentCredit";
import ManageOrderHistory from "../../components/manual/ManageOrderHistory";
import ManageTracking from "../../components/manual/ManageTracking";
import Search from "../../components/manual/Search";

const manualSections = [
  {
    id: "intro",
    title: "서비스 소개",
    overview: "**몰은 도매업체를 위한 종합 주문관리 플랫폼입니다.",
  },
  {
    id: "order",
    title: "주문하기",
    overview:
      "엑셀 주문과 픽업 주문을 통해 효율적으로 상품을 주문하실 수 있습니다.",
    subSections: [
      { id: "excel-order", title: "엑셀 주문", badge: "NEW" },
      { id: "pickup-order", title: "픽업 주문" },
    ],
  },
  {
    id: "payment",
    title: "결제하기",
    overview: "선불충전금, 포인트, 크레딧 등 다양한 결제 수단을 제공합니다.",
    subSections: [
      { id: "prepaid", title: "선불충전금" },
      { id: "point", title: "포인트" },
      { id: "credit", title: "크레딧" },
    ],
  },
  {
    id: "manage",
    title: "주문관리",
    overview: "주문 내역 조회와 실시간 배송 현황을 확인하실 수 있습니다.",
    subSections: [
      { id: "order-history", title: "주문내역" },
      { id: "tracking", title: "배송조회" },
    ],
  },
  {
    id: "search",
    title: "제품검색",
    overview: "실시간 재고 현황과 가격 정보를 손쉽게 확인하실 수 있습니다.",
  },
];

const ManualPage = () => {
  const [activeSection, setActiveSection] = React.useState("intro");
  const [activeSubSection, setActiveSubSection] = React.useState(null);

  const renderContent = () => {
    if (activeSection === "intro") {
      return <Introduce />;
    }

    if (activeSection === "search") {
      return <Search />;
    }

    switch (`${activeSection}-${activeSubSection}`) {
      case "order-excel-order":
        return <OrderExcel />;

      case "order-pickup-order":
        return <OrderPickup />;

      case "payment-prepaid":
        return <PaymentPrepaid />;

      case "payment-point":
        return <PaymentPoint />;

      case "payment-credit":
        return <PaymentCredit />;

      case "manage-order-history":
        return <ManageOrderHistory />;

      case "manage-tracking":
        return <ManageTracking />;

      // 나머지 케이스들은 동일하게 유지...
      default:
        return activeSection !== "intro" && <p>세부 메뉴를 선택해주세요.</p>;
    }
  };

  return (
    <Container fluid className="mt-4">
      <Row>
        <Col md={3}>
          <Card className="shadow-sm border-0 bg-light">
            <CardBody>
              <CardTitle tag="h5" className="mb-4">
                사용자 매뉴얼
              </CardTitle>
              <Nav vertical className="manual-nav">
                {manualSections.map((section) => (
                  <div key={section.id} className="mb-2">
                    <NavItem>
                      <NavLink
                        href="#"
                        className={
                          activeSection === section.id
                            ? "active fw-bold border-0"
                            : "text-secondary"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveSection(section.id);
                          setActiveSubSection(
                            section.subSections
                              ? section.subSections[0].id
                              : null
                          );
                        }}
                      >
                        {section.title}
                      </NavLink>
                    </NavItem>
                    {section.subSections && activeSection === section.id && (
                      <Nav vertical className="ms-3 sub-nav">
                        {section.subSections.map((subSection) => (
                          <NavItem key={subSection.id}>
                            <NavLink
                              href="#"
                              className={
                                activeSubSection === subSection.id
                                  ? "active border-0 text-primary fw-bold"
                                  : "text-secondary"
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                setActiveSubSection(subSection.id);
                              }}
                            >
                              {subSection.title}
                              {subSection.badge && (
                                <Badge color="primary" className="ms-2">
                                  {subSection.badge}
                                </Badge>
                              )}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    )}
                  </div>
                ))}
              </Nav>
            </CardBody>
          </Card>
        </Col>

        <Col md={9}>
          <Card className="shadow-sm border-0 bg-light mb-5">
            <CardBody>{renderContent()}</CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

// 스타일 정의
const styles = `
.manual-nav .nav-link {
  padding: 0.5rem 1rem;
  color: #495057;
  border-radius: 4px;
}

.manual-nav .nav-link:hover {
  background-color: #f8f9fa;
  color: #000;
}

.manual-nav .nav-link.active {
  background-color: #e9ecef;
  color: #000;
}

.sub-nav .nav-link {
  padding: 0.3rem 1rem;
  font-size: 0.9rem;
}

.video-container {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.card {
  border-radius: 8px;
}

.bg-light {
  background-color: #f8f9fa !important;
}


`;

export default ManualPage;
