import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

import "./App.css";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
// import ConfirmSignUp from "./pages/ConfirmSignUp";
import ErrorPage from "./pages/ErrorPage";

// 주문
import Upload from "./pages/order/Upload";
import UploadList from "./pages/order/UploadList";
import Pickup from "./pages/order/Pickup";
import PickupList from "./pages/order/PickupList";

// 주문관리
import Registered from "./pages/manage/Registered";
import Processing from "./pages/manage/Processing";
import Done from "./pages/manage/Done";
import Cancel from "./pages/manage/Cancel";

// 제품
import Product from "./pages/product/Product";

// 검색
import Search from "./pages/search/Search";

// 페이
import Deposit from "./pages/pay/Deposit";
import Point from "./pages/pay/Point";
import Credit from "./pages/pay/Credit";
import Invoice from "./pages/pay/Invoice";

// 관리자
import AdminRegistered from "./pages/admin/AdminRegistered";
import AdminProcessing from "./pages/admin/AdminProcessing";
import AdminDone from "./pages/admin/AdminDone";
import AdminCancel from "./pages/admin/AdminCancel";
import AdminLedger from "./pages/admin/AdminLedger";
import AdminSpecialPrice from "./pages/admin/AdminSpecialPrice";
import AdminCompany from "./pages/admin/AdminCompany";
import AdminUser from "./pages/admin/AdminUser";
import AdminOrderPickup from "./pages/admin/AdminOrderPickup";

// 메인
import Main from "./pages/Main";

// 매뉴얼
import ManualPage from "./pages/manual/ManualPage";

// 기타
import TransferToNZ from "./pages/etc/TransferToNZ";
import Pending from "./pages/Pending";
import UserPending from "./pages/UserPending";
import CompanyForm from "./pages/CompanyForm";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          {/* 기타 */}
          <Route path="/info/transfer" element={<TransferToNZ />} />

          <Route path="/pending" element={<Pending />} />
          <Route path="/userpending" element={<UserPending />} />

          <Route path="/" element={<Main />} />

          <Route path="/company" element={<CompanyForm />} />
          {/* <Route path="/confirm" element={<ConfirmSignUp />} /> */}
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:email" element={<ResetPassword />} />

          {/* 페이 */}
          <Route path="/pay/deposit" element={<Deposit />} />
          <Route path="/pay/point" element={<Point />} />
          <Route path="/pay/credit" element={<Credit />} />
          <Route path="/pay/invoice" element={<Invoice />} />

          {/* 주문 */}
          <Route path="/list" element={<UploadList />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/pickup" element={<Pickup />} />
          <Route path="/pickuplist" element={<PickupList />} />
          <Route path="/pickuplist/:status" element={<PickupList />} />

          {/* 주문관리 */}
          <Route path="/manage/registered" element={<Registered />} />
          <Route path="/manage/processing" element={<Processing />} />
          <Route path="/manage/done" element={<Done />} />
          <Route path="/manage/cancel" element={<Cancel />} />

          {/* 제품 */}
          <Route path="/product" element={<Product />} />

          {/* 검색 */}
          <Route path="/search/:keyword" element={<Search />} />
          <Route path="/search" element={<Search />} />

          {/* 관리자 */}
          <Route path="/admin/registered" element={<AdminRegistered />} />
          <Route path="/admin/processing" element={<AdminProcessing />} />
          <Route path="/admin/done" element={<AdminDone />} />
          <Route path="/admin/cancel" element={<AdminCancel />} />
          <Route path="/admin/ledger" element={<AdminLedger />} />
          <Route path="/admin/specialprice" element={<AdminSpecialPrice />} />
          <Route path="/admin/company" element={<AdminCompany />} />
          <Route path="/admin/user" element={<AdminUser />} />
          <Route path="/admin/order/pickup" element={<AdminOrderPickup />} />

          {/* 매뉴얼 */}
          <Route path="/manual" element={<ManualPage />} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
