import axios from "axios";
import React, { useState } from "react";
import { Button, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

function IntegrationSearchForm({ setExcelOrders, setPickupOrders }) {
  const [searchKeyword, setSearchKeyword] = useState({
    excelOrderNo: "",
    pickupOrderNo: "",
    receiver: "",
    hawb: "",
    pcc: "",
    startDate: "",
    endDate: "",
  });

  const resetForm = () => {
    setSearchKeyword({
      excelOrderNo: "",
      pickupOrderNo: "",
      receiver: "",
      hawb: "",
      pcc: "",
      startDate: "",
      endDate: "",
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSearchKeyword((prevKeyword) => ({
      ...prevKeyword,
      [name]: value,
    }));
  };

  const handleSearch = async (event) => {
    event.preventDefault();

    const hasValidKeyword = Object.values(searchKeyword).some(
      (value) => value.trim() !== ""
    );

    if (!hasValidKeyword) {
      alert("검색어를 입력해 주세요.");
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/search`,
        {
          params: searchKeyword,
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      const data = response.data.data;

      if (data.excelOrder.length > 0) {
        setExcelOrders(data.excelOrder);
      } else {
        setExcelOrders([]);
      }

      if (data.pickupOrder.length > 0) {
        setPickupOrders(data.pickupOrder);
      } else {
        setPickupOrders([]);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  return (
    <div className="p-3  mb-3 border bg-light">
      <Form onSubmit={handleSearch}>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label for="excelOrderNo">엑셀 주문번호</Label>
              <Input
                type="text"
                name="excelOrderNo"
                id="excelOrderNo"
                placeholder="엑셀 주문번호"
                value={searchKeyword.excelOrderNo}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="pickupOrderNo">픽업 주문번호</Label>
              <Input
                type="text"
                name="pickupOrderNo"
                id="pickupOrderNo"
                placeholder="픽업 주문번호"
                value={searchKeyword.pickupOrderNo}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="receiver">수취인 정보</Label>
              <Input
                type="text"
                name="receiver"
                id="receiver"
                placeholder="수취인의 이름, 주소, 전화번호"
                value={searchKeyword.receiver}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="pcc">개인통관부호</Label>
              <Input
                type="text"
                name="pcc"
                id="pcc"
                placeholder="개인통관부호"
                value={searchKeyword.pcc}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>{" "}
          <Col md={3}>
            <FormGroup>
              <Label for="hawb">운송장번호</Label>
              <Input
                type="text"
                name="hawb"
                id="hawb"
                placeholder="운송장번호"
                value={searchKeyword.hawb}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label for="startDate">시작일</Label>
              <Input
                type="date"
                name="startDate"
                id="startDate"
                placeholder="시작일"
                value={searchKeyword.startDate}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>{" "}
          <Col md={3}>
            <FormGroup>
              <Label for="endDate">종료일</Label>
              <Input
                type="date"
                name="endDate"
                id="endDate"
                placeholder="종료일"
                value={searchKeyword.endDate}
                onChange={handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <Button color="secondary" onClick={() => resetForm()}>
              초기화
            </Button>{" "}
            <Button type="submit" color="primary">
              검색
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default IntegrationSearchForm;
