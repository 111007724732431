import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from "react-moment";
import { Table } from "reactstrap";
import { Cookies } from "react-cookie";
import { addCommasToNumber } from "../../../utils/AboutNumber";
import ReactPaginate from "react-paginate";
import { orderDetail } from "../../print/orderDetail";
import { pickupOrderDetail } from "../../print/pickupOrderDetail";
const cookies = new Cookies();

function RequestDepositUsed({ isActive }) {
  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0); // 전체 페이지 수

  useEffect(() => {
    // 선불충전금 요약 정보 가져오기
    if (isActive) {
      getConfirmedDepositList();
    }
  }, [isActive, currentPage]);

  const getConfirmedDepositList = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/admin/deposit/used?page=${currentPage}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      setList(res.data.data.list);
      setCount(res.data.data.count.count);
      setPageCount(Math.ceil(res.data.data.count.count / 10));
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  // 페이지가 변경될 때마다 실행될 함수
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  return (
    <div>
      <Table hover bordered striped>
        <thead>
          <tr className="table-primary">
            <th className="text-center p-2 border">날짜</th>
            <th className="text-center p-2 border">업체명</th>
            <th className="text-center">주문내용</th>
            <th className="text-center p-2 border">금액</th>

            <th className="text-center p-2 border">메모</th>
          </tr>
        </thead>
        <tbody>
          {list && list.length > 0 ? (
            list.map((item, idx) => {
              return (
                <tr key={idx}>
                  <td className="text-center">
                    <Moment format="YYYY-MM-DD">{item.created_at}</Moment>
                  </td>

                  <td className="text-center">{item.wholesaler}</td>
                  <td className="text-center">
                    {item.order_no && (
                      <span
                        onClick={() => orderDetail(item.order_no)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        엑셀주문
                        {/* ({item.order_no}) */}
                      </span>
                    )}
                    {item.pickup_order_no && (
                      <span
                        onClick={() => pickupOrderDetail(item.pickup_order_no)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        픽업주문
                        {/* ({item.pickup_order_no}) */}
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                    ${addCommasToNumber(Math.abs(item.amount))}
                  </td>

                  <td className="text-center">{item.deposit_memo}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={8} className="text-center">
                확인대상 목록 없음
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ReactPaginate
          previousLabel={"이전"}
          nextLabel={"다음"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"item active "}
          breakClassName={"item break-me "}
          pageClassName={"item pagination-page "}
          disabledClassName={"disabled-page"}
        />
      </div>
    </div>
  );
}

export default RequestDepositUsed;
