import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { Container, Row, Col } from "reactstrap";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export default function NoSidebarCompanyLayout({ children }) {
  const { user, isLoading, signOut } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    checkActivation();
  }, []);

  const checkActivation = () => {
    if (!isLoading) {
      if (!user) {
        alert("check user");
        navigate("/login", { replace: true });
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(); // AWS Cognito 로그아웃
      cookies.remove("info");
      cookies.remove("status");
      cookies.remove("balance");
      navigate("/login"); // 로그인 페이지로 리다이렉션
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Header onSignOut={handleSignOut} />

      <div style={{ flex: 1 }}>
        <Container fluid>{children}</Container>
      </div>

      <Footer />
    </div>
  );
}
