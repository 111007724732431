import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Cookies } from "react-cookie";
import NoSidebarCompanyLayout from "../components/layout/NoSidebarCompanyLayout";

const cookies = new Cookies();

const CompanyForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    companyName: cookies.get("info").profile,
    address: "",
    managerName: "",
    phone: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 입력 값이 변경될 때 호출되는 함수
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // 폼 제출 함수

  const handleSubmit = async (e) => {
    // 엑셀 주문상태별 주문수
    e.preventDefault();

    if (isSubmitting) {
      return; // 이미 제출 중이면 무시
    }

    const wholesaler = cookies.get("info").profile;

    try {
      await axios.post(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/company`,
        {
          companyName: wholesaler,
          wholesaler: wholesaler,
          managerName: formData.managerName,
          managerTel: formData.phone,
          managerEmail: cookies.get("info").email,
          grade: "미정",
          creditPurchaseEnabled: false,
          gstIncluded: false,
          isActive: false,
          memo: "",
          address: formData.address,
        },
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      navigate("/login");
    } catch (err) {
      console.error(err);
    } finally {
      setIsSubmitting(false); // 제출 완료 또는 오류 발생 후 활성화
    }
  };

  return (
    <NoSidebarCompanyLayout>
      <Container>
        <Row>
          <Col md={6} className="mx-auto">
            <h2 className="my-5">
              {cookies.get("info").profile} 업체 정보 입력
            </h2>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="companyName">업체명</Label>
                <Input
                  type="text"
                  name="companyName"
                  id="companyName"
                  placeholder="업체명을 입력하세요"
                  value={formData.companyName}
                  onChange={handleChange}
                  disabled
                />
              </FormGroup>

              <FormGroup>
                <Label for="address">주소</Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="주소를 입력하세요"
                  value={formData.address}
                  onChange={handleChange}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label for="managerName">담당자 이름</Label>
                <Input
                  type="text"
                  name="managerName"
                  id="managerName"
                  placeholder="담당자 이름을 입력하세요"
                  value={formData.managerName}
                  onChange={handleChange}
                  required
                />
              </FormGroup>

              <FormGroup>
                <Label for="phone">전화번호</Label>
                <Input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="전화번호를 입력하세요"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </FormGroup>

              <Button type="submit" color="primary" block>
                입력완료
              </Button>
            </Form>
          </Col>
        </Row>
        <div className="p-5"></div>
      </Container>
    </NoSidebarCompanyLayout>
  );
};

export default CompanyForm;
