import React from "react";
import Moment from "react-moment";
import { Row, Col, Table } from "reactstrap";
import { pickupOrderDetail } from "../../print/pickupOrderDetail";
import { paymentMethod } from "../../../utils/ConvertWord";

function PickupOrderList({ wholesaler, detailPickupOrder }) {
  return (
    <div>
      <Row className="mt-5">
        <Col>
          <h5 className="mb-3">{wholesaler} 픽업주문 리스트(픽업완료 기준)</h5>
          {detailPickupOrder.length > 0 ? (
            <Table hover bordered>
              <thead>
                <tr className="table-primary">
                  <th className="text-center">번호</th>
                  <th className="text-center">주문번호</th>
                  <th className="text-center">메모</th>
                  <th className="text-center">등록일</th>
                  <th className="text-center">픽업일시</th>
                  <th className="text-center">총금액</th>
                  <th className="text-center">결제방법</th>
                </tr>
              </thead>
              <tbody>
                {detailPickupOrder.map((order, idx) => (
                  <tr key={idx}>
                    <th scope="row" className="text-center">
                      {idx + 1}
                    </th>
                    <td>
                      <span
                        onClick={() => pickupOrderDetail(order.pickup_order_no)}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        {order.pickup_order_no}
                      </span>
                    </td>
                    <td>{order.memo}</td>
                    <td className="text-center">
                      <Moment format="YYYY-MM-DD">{order.created_at}</Moment>
                    </td>
                    <td className="text-center">
                      <Moment format="YYYY-MM-DD">{order.pickup_at}</Moment>
                    </td>
                    <td className="text-center">${Math.abs(order.amount)}</td>
                    <td>
                      {order.transactions.map((t, idx) => {
                        return (
                          <li key={idx}>
                            {paymentMethod(t.type)} : ${Math.abs(t.amount)}
                          </li>
                        );
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="border text-center p-5">픽업 주문이 없습니다.</div>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default PickupOrderList;
