import React, { useState, useEffect } from "react";
import { Row, Col, Form, FormGroup, Input, Button, Table } from "reactstrap";

import NormalLayout from "../../components/layout/NormalLayout";

import Moment from "react-moment";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

import { Cookies } from "react-cookie";
import { addCommasToNumber } from "../../utils/AboutNumber";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InvoiceForm from "../../components/invoice/InvoiceForm";
import { invoiceStatus } from "../../utils/ConvertWord";
import { handlePrintMonthlyInvoice } from "../../components/print/MonthlyInvoice";
import PickupOrderList from "../list/PickupOrderList";
import ExcelOrderList from "../list/ExcelOrderList";

const cookies = new Cookies();

function Invoice() {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [summary, setSummary] = useState(null);
  const [excelOrders, setExcelOrders] = useState([]);
  const [pickupOrders, setPickupOrders] = useState([]);
  const [company, setCompany] = useState(null);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  const [invoiceData, setInvoiceData] = useState({
    gst: company && company.gst,
    gstNo: "126332017",
    billTo: {
      name: "",
      address: "",
      city: "",
    },
    invoiceNumber: "",
    date: "",
    excelOrders: [],
    pickupOrders: [],
    summary: {},
  });

  const years = Array.from({ length: currentYear - 2023 }, (_, i) =>
    (2024 + i).toString()
  );
  const months = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];

  useEffect(() => {
    // 해당 년월을 기준으로 인보이스 정보 가져오기
    getInvoice(year, month);
  }, []);

  // 주문목록 가져오기
  const getInvoice = async (checkYear, checkMonth) => {
    //  초기화
    setExcelOrders([]);
    setPickupOrders([]);

    try {
      const resList = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/invoice/${checkYear}/${checkMonth}`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );
      const tempExcelOrders = resList.data.data.excelOrder;
      const tempPickupOrders = resList.data.data.pickupOrder;

      const tempInvoice = resList.data.invoice;
      const tempCompany = resList.data.company;
      console.log("tempExcelorders", tempExcelOrders);
      console.log("tempPickupOrders", tempPickupOrders);
      console.log("company", tempCompany);

      setExcelOrders(tempExcelOrders);
      setPickupOrders(tempPickupOrders);
      setCompany(tempCompany[0]);

      // 요약 정보 생성(업체 정보, 제품 총액, GST, 배송비 총액)
      let amountDeposit = 0.0;
      let amountPoint = 0.0;
      let amountCredit = 0.0;
      let amountDeliveryFee = 0.0;

      let tempStatus =
        tempInvoice.length > 0 ? tempInvoice[0].invoice_status : "확인중";

      if (checkYear === currentYear && checkMonth === currentMonth) {
        tempStatus = "pending";
      }

      if (tempExcelOrders.length !== 0 || tempPickupOrders.length !== 0) {
        // 엑셀주문 결제방식별 총액 구하기
        tempExcelOrders.map((eo) => {
          amountDeposit += Math.abs(parseFloat(eo.transaction_info.deposit));
          amountPoint += Math.abs(parseFloat(eo.transaction_info.point));
          amountCredit += Math.abs(parseFloat(eo.transaction_info.credit));

          amountDeliveryFee += parseFloat(eo.delivery_fee);
        });

        // 픽업주문 결제방식별 총액 구하기
        tempPickupOrders.map((po) => {
          amountDeposit += Math.abs(parseFloat(po.transaction_info.deposit));
          amountPoint += Math.abs(parseFloat(po.transaction_info.point));
          amountCredit += Math.abs(parseFloat(po.transaction_info.credit));
        });

        const tempSummary = {
          status: tempStatus,
          year: checkYear,
          month: checkMonth,
          company: cookies.get("info").profile,
          amountProductPrice: (
            amountDeposit +
            amountPoint +
            amountCredit -
            amountDeliveryFee
          ).toFixed(2),
          amountDeliveryFee: amountDeliveryFee.toFixed(2),
          subTotal: (amountDeposit + amountPoint + amountCredit).toFixed(2),
          gst: (
            amountDeposit +
            amountPoint +
            amountCredit -
            amountDeliveryFee -
            (amountDeposit + amountPoint + amountCredit - amountDeliveryFee) /
              1.15
          ).toFixed(2),
          totalAmount: (amountDeposit + amountCredit).toFixed(2),
          amountDeposit,
          amountPoint,
          amountCredit,
        };
        setSummary(tempSummary);

        // 인보이스 내용 설정
        const tempInvoiceData = {
          gst: company && company.gst,
          billTo: {
            name: cookies.get("info").profile,
            address: "",
            city: "",
          },
          invoiceNumber: "",
          date: `${checkYear}-${checkMonth}-1`,
          excelOrders: tempExcelOrders,
          pickupOrders: tempPickupOrders,
          summary: tempSummary,
        };

        setInvoiceData(tempInvoiceData);
      } else {
        setSummary(null);
      }
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  const handleSearch = () => {
    if (year > currentYear) {
      alert("날짜 범위를 벗어났습니다.");
    } else {
      if (year === currentYear && month > currentMonth) {
        alert("날짜 범위를 벗어났습니다.");
      } else {
        getInvoice(year, month);
      }
    }
  };

  return (
    <NormalLayout>
      <div>
        <h3>결제리스트</h3>
      </div>
      <div className="p-3 bordered"></div>
      <Row>
        <Col>
          <Form inline="true">
            <FormGroup className="mr-3 d-flex align-items-center">
              <Input
                type="select"
                id="yearSelect"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                className="mr-3"
                style={{ width: "100px" }}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Input>
              <span style={{ marginRight: "20px" }}>년</span>
              <Input
                type="select"
                id="monthSelect"
                value={month}
                onChange={(e) => setMonth(parseInt(e.target.value))}
                className="mr-3"
                style={{ width: "100px" }}
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </Input>
              <span style={{ marginRight: "20px" }}>월</span>
              <Button color="primary" onClick={handleSearch}>
                검색
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>

      {summary && (
        <>
          <h5 className="mt-5">결제 요약</h5>
          <div className="p-3 border">
            <Row className="">
              <Col>
                <Row>
                  <Col>
                    <li>업체명 : {summary.company}</li>
                    <li>
                      주문거래 년월 : {summary.year}년 {summary.month}월
                    </li>
                    <li>상태 : {invoiceStatus(summary.status)}</li>
                  </Col>
                  <Col className="text-end">
                    {summary.status !== "pending" && (
                      // <Button color="warning">인보이스 다운로드</Button>
                      <>
                        {/* <PDFDownloadLink
                          document={<InvoiceForm invoice={invoiceData} />}
                          fileName="invoice.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading
                              ? "Loading document..."
                              : "인보이스 다운로드(PDF)"
                          }
                        </PDFDownloadLink> */}
                        <br />
                        <br />
                        <Button
                          color="warning"
                          size="lg"
                          onClick={() => handlePrintMonthlyInvoice(invoiceData)}
                        >
                          {summary.year}년 {summary.month}월 결제내역 출력
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>

                <Table hover bordered className="mt-3">
                  <thead>
                    <tr className="table-primary">
                      <th className="text-center">구분</th>
                      <th className="text-center">Price</th>

                      <th className="text-center">Amount NZD</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">
                        Product
                        {/* {company && company.gst && "(GST included)"} */}
                      </th>
                      <td className="text-center">
                        ${addCommasToNumber(summary.amountProductPrice)}
                      </td>

                      <td className="text-center">
                        ${addCommasToNumber(summary.amountProductPrice)}
                        {/* {company && company.gst ? (
                          <>(GST : ${addCommasToNumber(summary.gst)})</>
                        ) : (
                          <>(No GST)</>
                        )} */}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Shipping</th>
                      <td className="text-center">
                        ${addCommasToNumber(summary.amountDeliveryFee)}
                      </td>

                      <td className="text-center">
                        ${addCommasToNumber(summary.amountDeliveryFee)}
                      </td>
                    </tr>
                    {summary && summary.amountPoint > 0 && (
                      <>
                        <tr>
                          <th></th>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <th>Discount(포인트 결제)</th>
                          <td className="text-center">
                            - $
                            {addCommasToNumber(summary.amountPoint.toFixed(2))}
                          </td>
                          <td className="text-center">
                            - $
                            {addCommasToNumber(summary.amountPoint.toFixed(2))}
                            {/* {company && company.gst && (
                              <>
                                {" "}
                                (GST : -$
                                {addCommasToNumber(
                                  (
                                    summary.amountPoint -
                                    summary.amountPoint / 1.15
                                  ).toFixed(2)
                                )}
                                )
                              </>
                            )} */}
                          </td>
                        </tr>
                      </>
                    )}
                    <tr
                      style={{
                        borderTopWidth: "10px",
                        borderBottomWidth: "10px",
                      }}
                    >
                      <th scope="row">Total NZD</th>

                      <td></td>
                      <td className="text-center">
                        ${addCommasToNumber(summary.totalAmount)}
                      </td>
                    </tr>
                    {summary && summary.amountDeposit > 0 && (
                      <>
                        <tr>
                          <th></th>
                          <td></td>
                          <td></td>
                        </tr>
                        <tr>
                          <th>Less Amount Credited(선불충전금)</th>
                          <td></td>
                          <td className="text-center">
                            $
                            {addCommasToNumber(
                              summary.amountDeposit.toFixed(2)
                            )}
                          </td>
                        </tr>
                      </>
                    )}
                    <tr className="table-primary">
                      <th scope="row">Amount Due NZD</th>

                      <td></td>
                      <td
                        className="text-center"
                        style={{ fontWeight: "800", fontSize: "1.1rem" }}
                      >
                        ${addCommasToNumber(summary.amountCredit.toFixed(2))}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            {/* {summary.status === "unpaid" && (
              <div className="p-3 border">
                <p>Due Date: 인보이스 발급 된 달 15일 까지</p>
                <p>
                  Payments can be made by Direct Credit to Health Inside
                  Limited.
                  <br /> Account Name: Health Inside Limited <br />
                  Account Number: ANZ BANK 06-0193-0837308-00 <br />
                  Swift Code: ANZBNZ22XXX <br />
                </p>
                <p>
                  기타 안내 <br />
                  *International Payment - Sender bears all transaction fees*
                  <br />
                  *Please note that if the invoice is not paid by the due date,
                  the supply of goods may be suspended.
                </p>
              </div>
            )}
            {summary.status === "pending" && (
              <div className="p-3 border">
                <p>
                  다음 달 인보이스 청구 내역을 미리 확인 할 수 있습니다. <br />
                  단. 포인트 사용시 제품가격 할인으로 적용되어 제품금액이
                  상이하게 표시될 수 있습니다.
                </p>
              </div>
            )}
            {summary.status === "paid" && (
              <div className="p-3 border">
                <p>정산 완료일 때 안내</p>
              </div>
            )} */}
          </div>
        </>
      )}

      {/* 주문정보 상세보기 */}
      <ExcelOrderList excelOrders={excelOrders} />

      <PickupOrderList pickupOrders={pickupOrders} />
    </NormalLayout>
  );
}

export default Invoice;
