import { useState, useEffect } from "react";
import { confirmPassword } from "../components/auth/auth";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Form, FormGroup, Input, Button } from "reactstrap";
import logo from "../../src/assets/img/logo.png";

export default function ResetPassword() {
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  let { email } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await confirmPassword(email, confirmationCode, newPassword);
      // setSuccess(true);
      alert("비밀번호 변경이 완료되었습니다. 로그인 후 이용해 주세요.");
      navigate("/login");
    } catch (err) {
      alert(err.message);
    }
  };

  // if (success) {
  //   return (
  //     <div>
  //       <h2>Reset password</h2>
  //       <p>Your password has been reset successfully!</p>
  //       <Link to="/login">Login</Link>
  //     </div>
  //   );
  // }

  return (
    <Container className="login-container">
      <div className="p-5 square border border-primary rounded">
        <div className="text-center">
          <a href="/login">
            <img src={logo} alt="로고" className="mb-3" />
          </a>
          <h2>비밀번호 재설정</h2>
        </div>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Input type="text" placeholder="Email" value={email} disabled />
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              placeholder="확인코드 숫자6자리"
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <span>8자 이상, 숫자 포함 필수</span>
          </FormGroup>
          <Button type="submit" color="primary" block>
            변경하기
          </Button>
        </Form>
        {error && <p>{error}</p>}
      </div>
    </Container>
  );
}
