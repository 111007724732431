import React, { useState, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import NormalLayout from "../../components/layout/NormalLayout";
import Moment from "react-moment";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import PointHistoryCharged from "../../components/pay/PointHistoryCharged";
import PointHistoryUsed from "../../components/pay/PointHistoryUsed";

import { Cookies } from "react-cookie";
import { addCommasToNumber } from "../../utils/AboutNumber";
const cookies = new Cookies();

function Point() {
  const [activeTab, setActiveTab] = useState("적립");
  const [summary, setSummary] = useState({ deposit: 0, used: 0 });
  const [point, setPoint] = useState(0);

  useEffect(() => {
    getDepositHistory();
  }, []);

  const getDepositHistory = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/point/history`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      setSummary(res.data.data.summary);
      setPoint(res.data.data.point.point_balance);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  return (
    <NormalLayout>
      <h3 className="my-4">포인트</h3>

      <>
        <Row className="mb-3">
          <Col>
            <div
              className="border p-3 radios text-center"
              style={{ height: "100px" }}
            >
              포인트 :{addCommasToNumber(point)}
            </div>
          </Col>
          <Col>
            <div
              className="border p-3 radios text-center"
              style={{ height: "100px" }}
            >
              총 적립포인트 : {addCommasToNumber(summary.deposit)}
              <br />총 사용포인트 : {addCommasToNumber(summary.used)}
            </div>
          </Col>
        </Row>
        <Nav tabs className="mb-3 bg-light">
          {["적립", "사용"].map((tab) => (
            <NavItem key={tab}>
              <NavLink
                className={activeTab === tab ? "active" : ""}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <Container style={{ marginLeft: "0px" }}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="적립" className="border-0">
              <PointHistoryCharged isActive={activeTab === "적립"} />
            </TabPane>
            <TabPane tabId="사용" className="border-0">
              <PointHistoryUsed isActive={activeTab === "사용"} />
            </TabPane>
          </TabContent>
        </Container>
      </>
    </NormalLayout>
  );
}

export default Point;
