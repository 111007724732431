import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import axios from "axios";
import { addCommasToNumber } from "../../utils/AboutNumber";
import DepositHistoryUsed from "./DepositHistoryUsed";
import DepositHistoryCharged from "./DepositHistoryCharged";
import { Cookies } from "react-cookie";
const cookies = new Cookies();

function DepositHistory({ isActive }) {
  const [activeTab, setActiveTab] = useState("적립");
  const [summary, setSummary] = useState({ deposit: 0, used: 0 });
  const [deposit, setDeposit] = useState(0);

  useEffect(() => {
    if (isActive) {
      // 포인트 리스트 가져오기
      getDepositHistory();
    }
  }, [isActive]);

  const getDepositHistory = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/deposit/history`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      setSummary(res.data.data.summary);
      setDeposit(res.data.data.deposit.deposit_balance);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  return (
    <>
      <Row className="mb-3">
        <Col>
          <div
            className="border p-3 radios text-center"
            style={{ height: "100px" }}
          >
            선불 충전금 :${addCommasToNumber(deposit)}
          </div>
        </Col>
        <Col>
          <div
            className="border p-3 radios text-center"
            style={{ height: "100px" }}
          >
            총 적립금액 : ${addCommasToNumber(summary.deposit)}
            <br />총 사용금액 :${addCommasToNumber(summary.used)}
          </div>
        </Col>
      </Row>
      <Nav tabs className="mb-3 bg-light">
        {["적립", "사용"].map((tab) => (
          <NavItem key={tab}>
            <NavLink
              className={activeTab === tab ? "active" : ""}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </NavLink>
          </NavItem>
        ))}
      </Nav>

      <Container style={{ marginLeft: "0px" }}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="적립" className="border-0">
            <DepositHistoryCharged isActive={activeTab === "적립"} />
          </TabPane>
          <TabPane tabId="사용" className="border-0">
            <DepositHistoryUsed isActive={activeTab === "사용"} />
          </TabPane>
        </TabContent>
      </Container>
    </>
  );
}

export default DepositHistory;
