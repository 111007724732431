export const orderStatus = (status) => {
  switch (true) {
    case status === "REGISTERED":
      return "주문등록 완료";
    case status === "PROCESSING":
      return "주문 처리 중";
    case status === "DONE":
      return "발송완료";
    case status === "CANCEL":
      return "주문취소";

    default:
      return "해당되는 단계가 없습니다";
  }
};

export const pickupOrderStatus = (status) => {
  switch (true) {
    case status === "REGISTERED":
      return "픽업주문등록";
    case status === "PROCESSING":
      return "준비 중";
    case status === "READY":
      return "제품 준비 완료";
    case status === "DONE":
      return "완료";
    case status === "CANCEL":
      return "취소";
    default:
      return "해당되는 단계가 없습니다";
  }
};

export const invoiceStatus = (status) => {
  switch (true) {
    case status === "pending":
      return "대기중";
    case status === "unpaid":
      return "정산전";
    case status === "checking":
      return "정산확인중";
    case status === "paid":
      return "정산완료";
    default:
      return "해당되는 단계가 없습니다";
  }
};

export const paymentMethod = (status) => {
  switch (true) {
    case status === "deposit":
      return "선불충전금";
    case status === "point":
      return "포인트";
    case status === "credit":
      return "크레딧";
    default:
      return "결제 전 또는 해당되는 지불방식이 없습니다";
  }
};
