import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import NormalLayout from "../../components/layout/NormalLayout";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import CreditHistoryCharged from "../../components/pay/CreditHistoryCharged";
import CreditHistoryUsed from "../../components/pay/CreditHistoryUsed";

import { Cookies } from "react-cookie";
import { addCommasToNumber } from "../../utils/AboutNumber";
const cookies = new Cookies();

function Credit() {
  const [activeTab, setActiveTab] = useState("정산");
  const [summary, setSummary] = useState({ calculate: 0, used: 0 });
  const [credit, setCredit] = useState(0);

  useEffect(() => {
    getDepositHistory();
  }, []);

  const getDepositHistory = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_WHOLESALE_API_URL}/credit/history`,
        {
          headers: {
            Authorization: cookies.get("info").token,
          },
        }
      );

      console.log(res.data.data);

      setSummary(res.data.data.summary);
      setCredit(res.data.data.credit.credit_balance || 0);
    } catch (error) {
      console.error("데이터를 불러오는데 실패했습니다", error);
    }
  };

  return (
    <NormalLayout>
      <h3 className="my-4">크레딧</h3>

      <>
        <Row className="mb-3">
          <Col>
            <div
              className="border p-3 radios text-center"
              style={{ height: "100px" }}
            >
              크레딧 : {addCommasToNumber(Math.abs(credit))}
            </div>
          </Col>
          <Col>
            <div
              className="border p-3 radios text-center"
              style={{ height: "100px" }}
            >
              총 정산금액 : {addCommasToNumber(summary.calculate || 0)}
              <br />총 신용구매 : {addCommasToNumber(summary.used || 0)}
            </div>
          </Col>
        </Row>
        <Nav tabs className="mb-3 bg-light">
          {["정산", "사용"].map((tab) => (
            <NavItem key={tab}>
              <NavLink
                className={activeTab === tab ? "active" : ""}
                onClick={() => setActiveTab(tab)}
              >
                {tab}
              </NavLink>
            </NavItem>
          ))}
        </Nav>

        <Container style={{ marginLeft: "0px" }}>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="정산" className="border-0">
              <CreditHistoryCharged isActive={activeTab === "정산"} />
            </TabPane>
            <TabPane tabId="사용" className="border-0">
              <CreditHistoryUsed isActive={activeTab === "사용"} />
            </TabPane>
          </TabContent>
        </Container>
      </>
    </NormalLayout>
  );
}

export default Credit;
